var TableManager = {
    init: function(){
      this.initSearch();
      this.initClick();
    },
    initSearch : function(){
        if( $("#table-search").length == 0 )
            return;

        // FILTER list
        $('#table-search').on("keyup",function(){
            var $tableContainer = $(this).parents('.table-container');
            var results = $tableContainer.find('table tbody tr');
            var colSearch = $tableContainer.find('.search-container').data('search-col');
            results.show();
            var val = $(this).val().toLowerCase();
            if(val.length > 2){
                $(results).each(function(i,res){
                    var name = $(res).find('.col-'+colSearch).text().trim().toLowerCase();
                    if(name.indexOf(val) != -1){
                        $(res).show();
                    } else {
                        $(res).hide();
                    }
                });
            }
        });
    },
    initClick : function(){
        $('body').on('dblclick','.table-container table tbody tr[data-href]',function(){
           var href = $(this).data('href');
           if($(this).hasClass('popinify')){
               Popin.open(href);
           } else {
               App.gotoPage(href);
           }
        });
    }
}