var Front_IntroVigneronsController = {

    presentationTimeout: false,
    presentationSlider: false,
    // Methods
    init: function () {
        $("body").on('click', 'a.see-video', function (e) {
            e.preventDefault();
            var iframe = $("#video iframe");
            iframe.attr('src', iframe.data('src'));
            $('body,html').animate({
                scrollTop: $('#bloc-video').offset().top
            }, 600);
        });
    },
    presentationAction: function () {
        Front_IntroVigneronsController.initPresentationSlider();
    },
    initPresentationSlider: function () {
        if (!Device.isTabletteXS) {
            // Desktop
            if (Front_IntroVigneronsController.presentationSlider) {
                Front_IntroVigneronsController.presentationSlider.destroySlider();
                Front_IntroVigneronsController.presentationSlider = false;
            }
        }
        else {
            // Tablette
            if (!Front_IntroVigneronsController.presentationSlider) {
                Front_IntroVigneronsController.presentationSlider = $('.module-front.controller-intro-vignerons .bloc-top .icons').bxSlider({
                    controls: false
                });
            }
        }
    },
    onResize: function (windowWidth, windowHeight) {
        if (App.action == 'presentation') {
            if (Front_IntroVigneronsController.presentationTimeout)
                clearTimeout(Front_IntroVigneronsController.presentationTimeout);
            Front_IntroVigneronsController.presentationTimeout = setTimeout(Front_IntroVigneronsController.initPresentationSlider, 1000);
        }
    }
};
