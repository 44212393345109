var IntroductionTabs = {
    actions: ["introVignerons-presentation", "introPartners-presentation"], // controller-actions where this should be initialized
    iconsSlider: [],
    timeoutResize: false,
    prevTab: 1,
    init: function () {
        var self = this;

        var action = App.controller + "-" + App.action;
        if ($.inArray(action, self.actions) === -1) {
            return;
        }

        var controllerName = App.controller.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        var actionName = App.action.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

        var $body = $('body');
        $body.on('click', '.module-front.controller-' + controllerName + '.action-' + actionName + ' .bloc-top .icons a', function (e) {
            e.preventDefault();
            var tab = $(this).data('tab');

            self.setTab(tab, true);

        });
        $body.on('click', '.module-front.controller-' + controllerName + '.action-' + actionName + ' #tabs-menu a', function (e) {
            e.preventDefault();
            var tab = $(this).data('tab');

            self.setTab(tab, false);
        });
        $body.on('click', '.module-front.controller-' + controllerName + '.action-' + actionName + ' #tabs-items a.tab-name', function (e) {
            e.preventDefault();
            var tab = $(this).data('tab');

            self.setTab(tab, false);
        });
        $body.on('click', '.module-front.controller-' + controllerName + '.action-' + actionName + ' #bloc-pourquoi #tabs-items .icons .icon', function (e) {
            e.preventDefault();
            var tab = $(this).data('tab');

            $('#tabs-items .tab.active .icon,#tabs-items .descr').removeClass('active');
            $('#tabs-items .descr-' + tab).addClass('active');
            $(this).addClass('active');
        });
        IntroductionTabs.contructTabSlider();
    },
    contructTabSlider: function () {

        if (Device.isMobile) {
            if(!IntroductionTabs.iconsSlider.length){
                $('#tabs-items .tab.active .icons').each(function () {
                    var slider = $(this);
                    IntroductionTabs.iconsSlider.push(slider.bxSlider({
                        slideSelector: 'a.icon',
                        pager: false,
                        prevText: '',
                        nextText: '',
                        adaptiveHeight: true,
                        onSlideAfter: function ($slideElement, oldIndex, newIndex) {
                            slider.find('a.icon').removeClass('active');
                            $slideElement.addClass('active').trigger('click');
                        }
                    }));
                });
            }
        }
        else {
            IntroductionTabs.destroyTabSlider();
        }
    },
    destroyTabSlider: function () {

        if (IntroductionTabs.iconsSlider.length) {
            for (var i in IntroductionTabs.iconsSlider) {
                var slider = IntroductionTabs.iconsSlider[i];
                slider.destroySlider();
            }
            IntroductionTabs.iconsSlider = [];
        }
    },
    setTab: function (num, scroll) {
        if (IntroductionTabs.prevTab == num) {
            return;
        }
        $('#bloc-pourquoi #tabs-menu .tab,#bloc-pourquoi #tabs-items .tab,#bloc-pourquoi #tabs-items a.tab-name').removeClass('active').filter('.tab-' + num).addClass('active');

        if (scroll) {
            $('body,html').animate({
                scrollTop: $('#tabs-menu').offset().top
            }, 600);
        }
        IntroductionTabs.destroyTabSlider();
        IntroductionTabs.contructTabSlider();
        IntroductionTabs.prevTab = num;
    },
    onResize: function () {
        if (App.action == 'presentation') {
            clearTimeout(IntroductionTabs.timeoutResize);
            IntroductionTabs.timeoutResize = setTimeout(IntroductionTabs.contructTabSlider, 1000);
        }
    }
};
