var styles = window.getComputedStyle(document.documentElement, '');
var cssPrefix = (Array.prototype.slice
    .call(styles)
    .join('')
    .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o'])
)[1];

CSS = {
    /**
     * Generates CSS3's translate3d transformation style for Opera, Chrome/Safari, Firefox and IE
     *
     * @method translate3d
     * @param {Number} x The X axis coordinate
     * @param {Number} y The Y axis coordinate
     * @param {Number} z The Z axis coordinate
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return {String} The css style code
     */
    translate3d: function (x, y, z, t) {
        t = (typeof t === "undefined") ? 0 : t; //defaults to 0
        x = x.toString();
        y = y.toString();
        z = z.toString();
        if (x.substring(x.length - 1) != '%')
            x = parseInt(x) + 'px';
        if (y.substring(y.length - 1) != '%')
            y = parseInt(y) + 'px';
        if (z.substring(z.length - 1) != '%')
            z = parseInt(z) + 'px';
        if (Modernizr.csstransforms3d) {
            var tr = '-' + cssPrefix + '-transform: translate3d(' + x + ', ' + y + ', ' + z + ');' +
//            '-moz-transform: translate3d(' + x + ', ' + y + ', ' + z + ');' +
//            '-ms-transform: translate3d(' + x + ', ' + y + ', ' + z + ');' +
//            '-o-transform: translate(' + x + ', ' + y + ');' +
                'transform: translate3d(' + x + ', ' + y + ', ' + z + ');';
            if (t > 0) {
                tr +=
                    ' -' + cssPrefix + '-transition: ' + t + 'ms;' +
//                '-moz-transition: ' + t + 'ms;' +
//                '-ms-transition: ' + t + 'ms;' +
//                '-o-transition: ' + t + 'ms;' +
                'transition: ' + t + 'ms;';
            }
            return tr;
        }
        else {
            return 'margin-top:' + y;
        }

    },
    /**
     * Generates CSS3's translate transformation style for Opera, Chrome/Safari, Firefox and IE
     *
     * @method translate3d
     * @param {Number} x The X axis coordinate
     * @param {Number} y The Y axis coordinate
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return {String} The css style code
     */
    translate: function (x, y, t) {
        t = (typeof t === "undefined") ? 0 : t; //defaults to 0
        x = x.toString();
        y = y.toString();
        if (x.substring(x.length - 1) != '%')
            x = parseInt(x) + 'px';
        if (y.substring(y.length - 1) != '%')
            y = parseInt(y) + 'px';
        var tr = '-' + cssPrefix + '-transform: translate(' + x + ', ' + y + ');' +
//            '-moz-transform: translate3d(' + x + ', ' + y + ', ' + z + ');' +
//            '-ms-transform: translate3d(' + x + ', ' + y + ', ' + z + ');' +
//            '-o-transform: translate(' + x + ', ' + y + ');' +
            'transform: translate(' + x + ', ' + y + ');';
        if (t > 0) {
            tr +=
                ' -' + cssPrefix + '-transition: ' + t + 'ms;' +
//                '-moz-transition: ' + t + 'ms;' +
//                '-ms-transition: ' + t + 'ms;' +
//                '-o-transition: ' + t + 'ms;' +
            'transition: ' + t + 'ms;';
        }

        return tr;
    },

    /**
     * Generates CSS3's scale3d transformation style for Opera, Chrome/Safari, Firefox and IE
     * The scaling is symetric, with the same value for width and height
     *
     * @method scale3d
     * @param {Number} s The scale
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return {String} The css style code
     */
    scale3d: function (s, y, z, t) {
        t = (typeof t === "undefined") ? 0 : t; //defaults to 0
        var tr =
            '-' + cssPrefix + '-transform: scale3d(' + s + ', ' + 1 + ', 1);' +
//            '-moz-transform: scale3d(' + s + ', ' + 1 + ', 1);' +
//            '-ms-transform: scale3d(' + s + ', ' + 1 + ', 1);' +
//            '-o-transform: scale(' + s + ');' +
            'transform: scale3d(' + s + ', ' + 1 + ', 1);';

        if (t > 0) {
            tr += ' -' + cssPrefix + '-transition: ' + t + 'ms;' +
//                '-moz-transition: ' + t + 'ms;' +
//                '-ms-transition: ' + t + 'ms;' +
//                '-o-transition: ' + t + 'ms;' +
            'transition: ' + t + 'ms;';
        }
        return tr;
    },
    /**
     * Generates CSS3's scale3d transformation style for Opera, Chrome/Safari, Firefox and IE
     * The scaling is symetric, with the same value for width and height
     *
     * @method scale3d
     * @param {Number} s The scale
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return {String} The css style code
     */
    scale3d2: function (s) {
        t = (typeof t === "undefined") ? 0 : t; //defaults to 0
        var tr =
            '-' + cssPrefix + '-transform: scale3d(' + s + ', ' + s + ', ' + s + ');' +
//            '-moz-transform: scale3d(' + s + ', ' + 1 + ', 1);' +
//            '-ms-transform: scale3d(' + s + ', ' + 1 + ', 1);' +
//            '-o-transform: scale(' + s + ');' +
            'transform: scale3d(' + s + ', ' + s + ', ' + s + ');';

        if (t > 0) {
            tr += ' -' + cssPrefix + '-transition: ' + t + 'ms;' +
//                '-moz-transition: ' + t + 'ms;' +
//                '-ms-transition: ' + t + 'ms;' +
//                '-o-transition: ' + t + 'ms;' +
            'transition: ' + t + 'ms;';
        }
        return tr;
    },

    /**
     * Used to move a scaled element using translate, while keeping the scale
     * Generates the required CSS3 style for Opera, Chrome/Safari, Firefox and IE
     *
     * @method zoomTo
     * @param {Number} x The X axis coordinate of the transformation
     * @param {Number} y The Y axis coordinate of the transformation
     * @param {Number} s The scale of the element (symetric, with the same value for width and height)
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return The css style code
     */
    zoomTo: function (x, y, s, t) {
        s = (typeof s === "undefined") ? 2 : s; //defaults to 2
        t = (typeof t === "undefined") ? 0 : t; //defaults to 0

        var tr = '-' + cssPrefix + '-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-moz-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-ms-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-o-transform: translate(' + x + 'px, ' + y + 'px) scale(' + s + ');' +
            'transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
            '-' + cssPrefix + '-transition: ' + t + 'ms;' +
//            '-moz-transition: ' + t + 'ms;' +
//            '-ms-transition: ' + t + 'ms;' +
//            '-o-transition: ' + t + 'ms;' +
            'transition: ' + t + 'ms;';

        return tr;
    },
    /**
     * Used to move a scaled element using translate, while keeping the scale
     * Generates the required CSS3 style for Opera, Chrome/Safari, Firefox and IE
     *
     * @method zoomTo
     * @param {Number} x The X axis coordinate of the transformation
     * @param {Number} y The Y axis coordinate of the transformation
     * @param {Number} s The scale of the element (symetric, with the same value for width and height)
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return The css style code
     */
    rotate: function (angle) {


        var tr = '-' + cssPrefix + '-transform: rotate(' + angle + 'deg) -' + cssPrefix + '-translate3d(0,0,0);' +
//            '-ms-transform: rotate('+angle+'deg) -ms-translate3d(0,0,0);'+
//            '-webkit-transform: rotate('+angle+'deg) -webkit-translate3d(0,0,0);'+
            'transform: rotate(' + angle + 'deg) translate3d(0,0,0);';
//        var tr = '-webkit-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-moz-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-ms-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-o-transform: translate(' + x + 'px, ' + y + 'px) scale(' + s + ');' +
//            'transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-webkit-transition: ' + t + 'ms;' +
//            '-moz-transition: ' + t + 'ms;' +
//            '-ms-transition: ' + t + 'ms;' +
//            '-o-transition: ' + t + 'ms;' +
//            'transition: ' + t + 'ms;';

        return tr;
    },
    /**
     * Used to move a scaled element using translate, while keeping the scale
     * Generates the required CSS3 style for Opera, Chrome/Safari, Firefox and IE
     *
     * @method zoomTo
     * @param {Number} x The X axis coordinate of the transformation
     * @param {Number} y The Y axis coordinate of the transformation
     * @param {Number} s The scale of the element (symetric, with the same value for width and height)
     * @param {Number} t The transition time / animation duration, defaults to 0
     * @return The css style code
     */
    rotate3d: function (angle) {


        var tr = '-' + cssPrefix + '-transform: rotate3d(0,0,1,' + angle + 'deg);' +
//            '-ms-transform: rotate3d(0,0,1,'+angle+'deg);'+
//            '-webkit-transform: rotate3d(0,0,1,'+angle+'deg);'+
            'transform: rotate3d(0,0,1,' + angle + 'deg);';
//        var tr = '-webkit-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-moz-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-ms-transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-o-transform: translate(' + x + 'px, ' + y + 'px) scale(' + s + ');' +
//            'transform: translate3d(' + x + 'px, ' + y + 'px, 0px) scale3d(' + s + ', ' + s + ', 1);' +
//            '-webkit-transition: ' + t + 'ms;' +
//            '-moz-transition: ' + t + 'ms;' +
//            '-ms-transition: ' + t + 'ms;' +
//            '-o-transition: ' + t + 'ms;' +
//            'transition: ' + t + 'ms;';

        return tr;
    }
};
