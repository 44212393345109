var VigneronsMenu = {

    // Methods
    init: function () {
        $('#vignerons-menu .vignette > a').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
        })
    }
};
