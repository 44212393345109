var AppEvents = {
    init : function(){
        $(document).on('click','.Front_Form_Reservation legend',function(e){
            e.preventDefault();
            $(this).parents('fieldset').toggleClass('open');
        });

        $(document).on('click','.container-filters-btn .big-btn-list',function(e){
            e.preventDefault();
            $(this).toggleClass('open');
        });

        $(document).on('change','#date',function(e){
            e.preventDefault();
            if($(this).parents('.date-container').hasClass('remove-date')){
                return;
            }
            $(this).parents('.date-container').addClass('remove-date');
            $(this).parents('.date-container').append('<span class="remove-date live-added remove-date-action icon-delete-1-1"></span>');
        });

        $(document).on("change", "#nb_adultes", function (e) {
            e.preventDefault(); //?
            if (
                $(this).parents(".guests-container").hasClass("remove-guests")
            ) {
                return;
            }
            $(this).parents(".guests-container").addClass("remove-guests");
            $(this)
                .parents(".guests-container")
                .append(
                    '<span class="remove-guests live-added remove-guests-action icon-delete-1-1"></span>'
                );
        });
    },
};
