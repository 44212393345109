function detectRobot(userAgent) {
  const robots = new RegExp([
    /bot/,/spider/,/crawl/,                            // GENERAL TERMS
    /APIs-Google/,/AdsBot/,/Googlebot/,                // GOOGLE ROBOTS
    /mediapartners/,/Google Favicon/,
    /FeedFetcher/,/Google-Read-Aloud/,
    /DuplexWeb-Google/,/googleweblight/,
    /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
    /ecosia/,/ia_archiver/,
    /facebook/,/instagram/,/pinterest/,/reddit/,       // SOCIAL MEDIA
    /slack/,/twitter/,/whatsapp/,/youtube/,
    /semrush/,                                         // OTHER
  ].map(
    function (r) {return r.source}
  ).join("|"),"i"); // BUILD REGEXP + "i" FLAG

  return robots.test(userAgent);
}

var Obfuscation = {
  unobfuscate: function () {
    (function($) {
      $('span.thumbnail-link').each(function () {
        var ref = this.getAttribute('data-ref');
        var data = JSON.parse(atob(ref.replace(ref.substring(4, 7), "")));
        var isRobot = detectRobot(navigator.userAgent)
        if (data.link && !isRobot) {
          const link = document.createElement('a');
          link.innerHTML = this.innerHTML;
          link.setAttribute('href', data.link);
          this.classList.remove('thumbnail-link')
          if (this.classList.length > 0)  {
            link.classList = this.classList;
          }
          var style = this.getAttribute('style');
          if (style)  {
            link.setAttribute('style', style);
          }
          if (data.target) {
            link.setAttribute('target', data.target);
          }
          if (data.rel) {
            link.setAttribute('rel', data.rel);
          }
          this.replaceWith(link)
        }
      });
    })(jQuery);
  }
};

Obfuscation.unobfuscate()
