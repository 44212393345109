var Device = {

    // Properties
    isMobile: false,
    isTabletteXS : false,
    isDisplayedMenuMobile: false,
    init: function () {
        Device.onResize($(window).width(), 0);
    },
    // Methods
    onResize: function (windowWidth, windowHeight) {
        Device.isMobile = windowWidth <= 600;
        Device.isTabletteXS = windowWidth <= 800;
        Device.isDisplayedMenuMobile = !!$("#menu_mobile:visible").size();
    }
};
