var OrderBtn = {
    init : function(){
        $('.container-order-btn.ajaxify #order').on('change',function(){
            var $tableContainer = $(this).parents('.container-order-btn').next('.table-container');
            $.ajax({
                url: $tableContainer.data('ajax-url'),
                data : {order : $(this).val()},
                cache: false,
                success: function (data) {
                    $tableContainer.replaceWith(data);
                    CssRadioCheckbox.parseAll();
                }
            });
        });


        $('.container-order-btn #order').on('change',function(){
            if ($(this).val() != '') {
                $("#value-order").text($('#order option:selected').text())
            }
        });
    }
}