var ViewHelpers = {

    // Methods
    reset: function () {
        Pepites.reset();
    },
    init: function () {
        // Dialog
        DialogV2.init();

        // Appearing Sticky
        AppearingSticky.init();

        // forms
        $('select.select2:not(.select2-hidden-accessible)').select2();
        MaxSelectionMultiSelect.init();
        FormRangeSlider.init();
        GoogleMapForm.init();

        AppEvents.init();

        Pepites.init();
        FormSearch.init();
        FullSearch.init();
        ClearableFilters.init();
        FormSearchDomainMobile.init();
        FormFilterActivitiesMobile.init();
        // Inputs autofill
        Autofill.init();
        MobileBottomBar.init();
        LinkBySms.init();
        Loading.init();
        Excerpt.init();
        FilteredContent.init();
        Ajaxify.init();
        Popin.init();
        Alertify.init();
        Facebook.init();
        Tooltip.init();
        HeaderFiltersTitle.init();
        GmapStaticLoader.init();
        GmapSharedEmbed.init();
        FullscreenSlider.init();
        ModuleReservation.init();
        MoveReservation.init();
        ModuleCommand.init();
        CssRadioCheckbox.init();
        Layout_Favorites.init();
        Layout_MenuMobile.init();
        Layout_CookieToolbar.init();
        FormMultiCheckboxElement.init();
        VigneronsMenu.init();
        IntroductionTabs.init();
        CodePostalAutocomplete.init();
        Faq.init();

        $(document).confirmHelper();
        $('.front-datepicker').each(function(){
            var input = $(this);
            input.attr('autocomplete','off');
            input.datetimepicker({
                lang: 'fr',
                timepicker: false,
                format: 'd/m/Y',
                minDate: input.data('mindate'),
                startDate: input.data('mindate'),
                maxDate: input.data('maxdate'),
                scrollInput: false,
                closeOnDateSelect: true,
                dayOfWeekStart: 1,
                onGenerate: function (ct) {
                    $('.xdsoft_datetimepicker ').addClass('search-datetimepicker');
                },
                onClose: function() {
                    $(this).parent().find('.clear-datepicker').addClass('visible');
                }
            });

            if($(this).hasClass('front-datepicker--allow-clear')){
                $('<a class="ico-cross-grey clear-datepicker"/>').insertAfter(this);
                if($(this).val() !== ''){
                    $(this).parent().find('.clear-datepicker').addClass('visible');
                }
            }
        });

        $('body').on('click', '.clear-datepicker', function () {
            $(this).prev().val('');
            $(this).removeClass('visible');
        });

        $('.front-datetimepicker').datetimepicker({
            lang: $('#CURRENT_LANG').val(),
            format: 'd/m/Y H:i',
            scrollInput: false,
            dayOfWeekStart: 1,
            closeOnDateSelect: true,
            onGenerate: function (ct) {
                $('.xdsoft_datetimepicker ')
                    .addClass('search-datetimepicker');
            }
        });

        if (typeof tinymce == 'object') {
            tinymce.init({
                selector: '.tinymce-light',
                font_formats: '',
                fontsize_formats: '',
                menubar: false,
                statusbar: false,
                content_css:'/css/reset-tinymce.css',
                toolbar: "bold | italic | underline"
            });
        }
        TableManager.init();
        OrderBtn.init();

        // Fixed Footer
        FixedFooter.init();

    },
    onResize: function (windowWidth, windowHeight) {
        Front_IndexController.onResize();
        Pepites.onResize();
        HeaderFiltersTitle.onResize();
        ModuleReservation.onResize();
        IntroductionTabs.onResize();
        FullSearch.onResize(windowWidth, windowHeight);
        FormSearch.onResize();
        AppearingSticky.onResize();
        FixedFooter.onResize();
    },
    onScroll: function (scrollTop) {
        FormSearch.onScroll(scrollTop);
        ModuleReservation.onScroll(scrollTop);
        AppearingSticky.onScroll(scrollTop);
        FullSearch.onScroll(scrollTop);
    }
};
