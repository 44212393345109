var MobileBottomBar = {
    mapForMobileInitialized: false,
    needZoomReset: true,
    initMapForMobile: function () {
        if (!MobileBottomBar.mapForMobileInitialized) {
            FullSearch.googleMap.setOptions({
                gestureHandling: 'greedy',
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_TOP,
                }
            });
        }
        MobileBottomBar.mapForMobileInitialized = true;
    },
    fixZoomIssue: function () {
        if (MobileBottomBar.needZoomReset) {
            var LatLngList = FullSearch.LatLngList;

            var bounds = new google.maps.LatLngBounds();
            //  Go through each...
            for (var i = 0, LtLgLen = LatLngList.length; i < LtLgLen; i++) {
                //  And increase the bounds to take this point
                bounds.extend(LatLngList[i]);
            }
            if (LatLngList.length) {
                //  Fit these bounds to the map
                FullSearch.googleMap.fitBounds(bounds);
            } else {
                FullSearch.googleMap.setZoom(5);
            }
        }
        MobileBottomBar.needZoomReset = false;
    },
    centerMapToScrolledDomain: function () {
        if ($('.layout-domain-container.first').length > 0) {
            var gpsStr = $('.layout-domain-container.first').data('gps');
            FullSearch.googleMap.setCenter(
                new google.maps.LatLng(
                    gpsStr.split(',')[0],
                    gpsStr.split(',')[1]
                )
            );
        }

        FullSearch.googleMap.setZoom(
            9
        );
    },
    showError: function(message) {

        if($('#g-tooltip-message').length > 0) {
            $('#g-tooltip-message').remove();
        }

        var close = $('.gps_error_close').text();
        var tooltipDom =
        $(
         '<div id="g-tooltip-message" class="tooltip testtt">\n' +
        '    <div class="layer_mobile">\n' +
        '        <div class="content"><p>'+  message + '</p>\n' +
        '            <div class="btn-border-green close-tooltip">'+ close +'</div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</div>');

        $('body').append(tooltipDom);
        tooltipDom.addClass('currently-displayed').find('.layer_mobile').fadeIn();
    },
    init: function () {
        //Around me
        $(document).on('click', '.js-btn-geoloc', function () {
            $("#loading-barre").removeClass('loaded');
            navigator.geolocation.getCurrentPosition(
                function (position) {
                    $('.form-search .results').addClass('hidden');
                    $('input#type_location').val('geoloc');
                    $('input#id_location').val(position.coords.latitude + ',' + position.coords.longitude);

                    $('input#location').val('Autour de moi');
                    $('#order').val('distance');

                    setTimeout(function () {
                        $('form.form-search').first().trigger('submit');
                    });
                }, function (error) {
                    $("#loading-barre").addClass('loaded');
                    var messageId = '.gps_position_error_'+error.code;
                    if($(messageId).length > 0) {
                        var message = $(messageId).text();
                        MobileBottomBar.showError(message);
                    }
                 }
                );
        });

        $(document).on('click', '.js-mobile-toggle-map', function () {
            if (
                $(
                    'main.controller-search.action-index, main.controller-experience.action-index, main.controller-wine-tour.action-index'
                ).hasClass('mobile-map-opened')
            ) {
                // will close
                $('main').removeClass('mobile-map-opened');
                $('body').removeClass('fc-disable-scroll');
                $('.filtersV2-sticky.mono-results-sticky')
                    .css('position', '')
                    .removeClass('fixed');
                $('.side-by-side-container > .right').css({
                    top: '',
                });
                $('#map').css({
                    top: '',
                });
                FullSearch.onResize(0, $(window).height());
                FullSearch.onScroll($(window).scrollTop());
            } else {
                // will open
                $(window).scrollTop(0);

                $('body').addClass('fc-disable-scroll');
                $('main').addClass('mobile-map-opened');
                $('.filtersV2-sticky.mono-results-sticky')
                    .css('position', 'fixed')
                    .addClass('fixed');

                var headerHeight = $(
                    '.filtersV2-sticky.mono-results-sticky'
                ).outerHeight();
                headerHeight = parseInt(headerHeight);
                var rightTop = isNaN(headerHeight) ? 0 : parseInt(headerHeight) - 1;
                $('.side-by-side-container > .right').css({
                    top: `${rightTop}px`,
                });
                $('#map').css({
                    top: 0,
                });
                MobileBottomBar.initMapForMobile();
                MobileBottomBar.fixZoomIssue();
            }
        });
    }
}
