var Tooltip = {
    init: function () {
        var $body = $("body");
        $body.on('click', ".tooltip a[href='#']", function (e) {
            e.preventDefault();
        });
        $body.on("click", ".tooltip", function (e) {
            e.stopPropagation();
            var $self = $(this);
            if ($("html").hasClass("touch")) { // we're on mobile or tablet
                $self.find(".layer_mobile").fadeIn();
                $self.addClass("currently-displayed");
            }
        });
        $body.on("click", ".close-tooltip", function (e) {
            e.stopPropagation();
            $(this).parentsUntil(".tooltip").last().fadeOut(function () {
                $(this).parent().removeClass("currently-displayed");
            });
        });
    }
};
