var Activities = {
    el: {
        container: $('.activities-container-ajax')
    },
    // Methods
    update: function (scrollToContainer) {
        if (scrollToContainer === undefined) {
            scrollToContainer = true;
        }
        var self = this;
        $(self.el.container).each(function (i, res) {
            $(self.el.container).addClass('loading');
            $.ajax({
                url: $(res).data('url'),
                cache: false,
                success: function (data) {
                    $(res).html(data);
                    if (scrollToContainer) {
                        let offset = $(
                            ".fullscreen-slider-super-container .form-inline"
                        ).outerHeight();
                        if (!offset) {
                            offset = $(
                                ".vignerons-create-reservation-container .form-inline"
                            ).outerHeight();
                        }
                        if (
                            !offset &&
                            parseInt($(".filtersV2-sticky").css("top")) === 0
                        ) {
                            offset = $(".filtersV2-sticky").outerHeight();
                        }
                        let containerTop = $(res).offset().top;
                        if ($(res).parents(".activities-section").length > 0) {
                            containerTop = $(res)
                                .parents(".activities-section")
                                .offset().top;
                        }
                        $("body,html").animate(
                            { scrollTop: containerTop - offset ?? 0 },
                            600
                        );
                    }
                    $(res).removeClass('loading');
                    if ($(res).parents('.filtered-content-container').size()) {
                        FilteredContent.update($(res).parents('.filtered-content-container'));
                    } else {
                        $(res).find('.filtered-content').show();
                    }
                }
            });
        });
    }
};
