var Faq = {
    // Methods
    init: function () {
        if ($("#faq").size()) {
            var $form = $("#help_search");
            var $form_input = $form.find("#query");
            var $category_wrappers = $(".category_wrapper");
            var $question_wrappers = $(".question_wrapper");
            var $questions = $question_wrappers.find(".question");
            var $answers = $question_wrappers.find(".answer");

            // search form
            $form.submit(function (e) {
                e.preventDefault();
                var $query = $form_input.val();
                var $question_wrappers_matching = $questions.add($answers).filter(":contains(" + $query + ")").parent();
                $question_wrappers.not($question_wrappers_matching).addClass("folded");
                $question_wrappers_matching.removeClass("folded");
                // hide or show categories
                $category_wrappers.each(function () {
                    var $self = $(this);
                    var $questions = $self.find(".question_wrapper");
                    if ($questions.size() == $questions.filter(".folded").size()) {
                        $self.slideUp();
                    } else {
                        $self.slideDown();
                    }
                });
                // hide or show questions
                setTimeout(function () {
                    $question_wrappers.not($question_wrappers_matching).slideUp();
                    $question_wrappers_matching.slideDown();
                }, 300);
            });

            $form_input.keyup(function () {
                $form.submit();
            });

            // questions click
            $questions.click(function () {
                var $self = $(this);
                var $answer = $self.next(".answer");
                $questions.not($self).removeClass("active");
                $answers.not($answer).slideUp();
                $self.toggleClass("active");
                $answer.stop().slideToggle();
            });
        }
    }
};
