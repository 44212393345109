var LinkBySms = {

    // Methods
    init: function () {
        $('body').on('submit', 'form#Front_Form_LinkBySms', function (e) {
            e.preventDefault();
            var form = $(this);
            $.ajax({
                cache: false,
                url: form.attr('action'),
                data: form.serialize(),
                type: 'post',
                success: function (data) {
                    form.replaceWith(data);
                }
            });
        });
    }
};
