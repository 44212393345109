var Front_TestsController = {


    // Methods
    init: function () {
    },
    // Methods
    mapAction: function () {

        // Load Google Map
        GoogleMap.load();
    },
    datepickerAction: function () {
        $('.date-picker').datetimepicker({
            lang: $('#CURRENT_LANG').val(),
            timepicker: false,
            format: 'd/m/Y',
            closeOnDateSelect: true,
            dayOfWeekStart: 1
        });
    },
    // Methods
    map2Action: function () {
        // Load Google Map
        GoogleMap.load('Front_TestsController.map2MapOK');
    },
    map2MapOK: function () {
        // Création de la Google Map
        GoogleMap.map = new google.maps.Map(GoogleMap.mapElement.get(0), {
            center: {lat: 45.7667, lng: 4.13333}, zoom: 8
        });

        var villes = JSON.parse($('#villes').val());
        for (var i = 0; i < villes.length; i++) {
            GoogleMap.addMarker(villes[i].latitude, villes[i].longitude, villes[i].nom, GoogleMap.map, 'red');
        }

        // Coordonnées Source / Destination
        var pointA = $('#pointA').val().split(',');
        var pointB = $('#pointB').val().split(',');

        // Marker Source / Destination
        GoogleMap.addMarker(parseFloat(pointA[1]), parseFloat(pointA[0]), 'Source', GoogleMap.map, 'green', 1000);
        GoogleMap.addMarker(parseFloat(pointB[1]), parseFloat(pointB[0]), 'Destination', GoogleMap.map, 'green', 1000);
    }
};
