var Layout_CookieToolbar = {
    init : function(){
        // Hide Cookie toolbar now !
        $.cookie('optin-cookies', 1, {path: '/', expires: 365});

        $(document).on('click','.cookie-toolbar-container button', function(){
            if (App.module == "front" && App.controller == "index" && App.action == "index") {
                $(".homepage-bloc-visuel").find(".barre").css("bottom", 0);
            }
            $(this).closest('.cookie-toolbar-container').slideUp(function(){
                $(this).removeClass('open').addClass('closed');
                $("#content").removeClass('not-cookies-optin').addClass('cookies-optin');

                $(window).trigger('resize');

            });
        });
    }
}
