var Facebook = {
    // Methods
    init: function () {

        window.fbAsyncInit = function () {
            FB.init({
                appId: App.fbAppId,
                xfbml: true,
                version: 'v2.3'
            });
        };

        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.defer = true;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

        $('body').on('click', 'a.facebook-connect', function (e) {
            e.preventDefault();
            var button = $(this);
            FB.login(function (response) {
                if (response.authResponse) {
                    if (button.hasClass('popinify')) {
                        Popin.open(button.attr('href'),{'facebook-access-token':response.authResponse.accessToken});
                    }
                }
            }, {scope: 'email'});
        });
    }
};
