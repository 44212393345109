var Front_SearchController = {
    supportsFlexbox      : false,
    // Methods
    init                 : function () {
        this.supportsFlexbox = $("html").hasClass("flexbox");

        this.faqSnippetArrow();
        this.setFirstVisible();
    },
    indexAction          : function () {
        this.setDomainsBlocksSizes();

        // // SEO banner
        // $("body").on("click", ".contenu-seo h1", function () {
        //     $(this).siblings(".description").slideToggle();
        // });
    },
    onResize             : function (windowWidth, windowHeight) {
        this.setDomainsBlocksSizes();
    },
    faqSnippetArrow: function () {
        const accordion = Accordion.init('.schema-faq-question');
        accordion.showOne();
    },
    setDomainsBlocksSizes: function () {
        if (!this.supportsFlexbox) {
            var $container = $(".search-domains-container");
            var $domains = $container.find(".layout-domain-container");
            var $prevDomain;
            var domainHeight, prevHeight;
            $domains.find(".inner").height("auto");
            if ($domains.size() && $domains.first().width() != $container.width()) {
                $domains.filter(":odd").each(function () {
                    var $self = $(this);
                    $prevDomain = $self.prev(".layout-domain-container");
                    domainHeight = $self.height();
                    prevHeight = $prevDomain.height();
                    if (domainHeight > prevHeight) {
                        $prevDomain.children(".inner").height(domainHeight);
                    } else {
                        $self.children(".inner").height(prevHeight);
                    }
                });
            }
        }
    },
    setFirstVisible: function () {
        $(window).scroll(function(){
            var scrollTop = $(window).scrollTop();
            var windowHeight = $(window).height();
            var first = false;
            $(".layout-domain-container").each( function() {
                var offset = $(this).offset();
                if (scrollTop <= offset.top && ($(this).height() + offset.top) < (scrollTop + windowHeight) && first == false) {
                    $(this).addClass("first");
                    first=true;
                } else {
                    $(this).removeClass("first");
                    first=false;
                }
            });
        });

        $(window).trigger('scroll');
    }
};
