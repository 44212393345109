var FormFilterActivitiesMobile = {
    init: function () {
        var isMobileFormComplete = function () {
            return $('#nb_adultes_mobile').val() !== ''
                && $('#date_mobile').val() !== ''
                && $('#lang_mobile').val() !== '';
        };
        $('.form-filter-activities-mobile input#date_mobile').datetimepicker({
            lang: $('#CURRENT_LANG').val(),
            timepicker: false,
            format: 'd/m/Y',
            scrollInput: false,
            scrollMonth: false,
            scrollTime: false,
            minDate: '0',
            dayOfWeekStart: 1,
            closeOnDateSelect: true,
            onGenerate: function (ct) {
                $('.xdsoft_datetimepicker ')
                    .addClass('search-datetimepicker');
            }
        });

        $('.form-filter-activities-mobile').on('submit', function (e) {
            e.preventDefault();

            $('.form-search-domain #lang').val($('#lang_mobile').val());
            $('.form-search-domain #date').val($('#date_mobile').val());

            setTimeout(function () {
                if (isMobileFormComplete()) {
                    $('form.form-search-domain').trigger('submit');
                } else {
                    alert(i18n['form-search-domain.error.all_filters_required']);
                }
            });

            return false;
        })

        $('.mobile-activity-filter .js-submit').on('click', function (e) {
            $('.form-filter-activities-mobile').trigger('submit');
        });
    }
};
