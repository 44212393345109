var Front_TunnelGiftCardsController = {
    // Methods
    nbSymbolsGiftCard: 3,

    initStripe: function () {
        if ($("#form-validation-card-command").length === 0) {
            return;
        }

        const stripePublicKey = window.globalValues && window.globalValues.stripe_public_key ?
            window.globalValues.stripe_public_key :
            null;

        if (null === stripePublicKey) {
            throw new Error('Stripe could not be initialized. No stripePublicKey found');
        }

        const stripe = Stripe(stripePublicKey);
        const pay = function () {
            const url = $("#tunnel-gift-card-create-command_url").val();
            $("ul.errors").remove();
            $(".form-group.errors").removeClass('errors');
            const fetchPaymentIntent = function () {
                return new Promise(function (resolve, reject) {
                    $.post(
                        url,
                        $("#form-validation-card-command").serialize(),
                        function (result) {
                            if (result.formErrors !== undefined) {
                                for (var property in result.formErrors) {

                                    var fieldErrors = result.formErrors[property];

                                    $('.form-group.' + property).addClass('error');

                                    var errorsContainer = $('<ul class="errors"></ul>');
                                    for (var errorKey in fieldErrors) {
                                        var li = $('<li></li>');
                                        li.text(fieldErrors[errorKey])
                                        li.appendTo(errorsContainer);
                                    }

                                    $('#' + property + '-element').append(errorsContainer);

                                }
                            }
                            if (result.error !== undefined) {
                                reject(result.error)
                                return;
                            }
                            resolve(result);
                        }, 'json').fail(function (error) {
                        reject(error);
                    })
                })
            };
            fetchPaymentIntent().then(function (result) {
                const clientSecret = result.client_secret;
                return stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: cardElement,
                    },
                })
            }).then(function (result) {
                return new Promise(function (resolve, reject) {
                    if (result.error !== undefined) {
                        dataLayer.push({
                            'event': 'payment_failed',
                            'error': result.error
                        });
                        reject(result.error.message);
                        return;
                    }
                    const url = $("#tunnel-gift-card-validate-command_url").val();

                    $.post(
                        url,
                        function (result) {
                            if (result.error !== undefined) {
                                dataLayer.push({
                                    'event': 'payment_failed',
                                    'error': result.error
                                });
                                reject(result.error)
                                return;
                            }
                            resolve(result);
                        }, 'json'
                    ).fail(function (error) {
                        dataLayer.push({
                            'event': 'payment_failed',
                            'error': error
                        });
                        reject(error);
                    })
                });
            }).then(function (result) {
                window.location = result.redirect_url;
            }).catch(function (error) {
                $('.loader').hide();

                if (typeof error === "object") {
                    alert(error.message);
                    if (error.redirectUrl !== undefined) {
                        window.location = error.redirectUrl;
                    }
                } else {
                    alert(error)
                }
            })
        };

        const setupElements = function () {
            const elements = stripe.elements({
                locale: window.globalValues.language,
            })

            const card = elements.create('card', {
                style: {
                    base: {
                        color: "#32325D",
                        fontWeight: 500,
                        fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
                        fontSize: "16px",
                        fontSmoothing: "antialiased",

                        "::placeholder": {
                            color: "#CFD7DF"
                        }
                    },
                    invalid: {
                        color: "#E25950"
                    }
                }
            })
            card.mount('#card-element')

            return card
        };

        const cardElement = setupElements();

        $('#validate').on('click', function (e) {
            e.preventDefault();

            if (false === $("#form-validation-card-command")[0].reportValidity()) {
                return;
            }
            $('.loader').show();

            pay();
        });
    },
    initMobileSticky: function(){
        $("#mobile-validate-button").on('click', function(){
            $('#form-validation-card-command #validate').click();
        })
    },
    init: function () {
        this.initStripe();
        this.initMobileSticky();
    },
    onScroll: function (scrollTop) {
    },
    onResize: function (windowWidth, windowHeight) {
    },
};
