var App = {
    // Properties
    module: false,
    controller: false,
    action: false,
    fbAppId: false,
    fbLanguage: false,

    // Methods
    start: function (module, controller, action, baseUrl, fbAppId, fbLanguage) {
        if (typeof PhoneApp == 'object') {
            $('*').off();
        }
        App.module = module;
        App.controller = controller;
        App.action = action;
        App.fbAppId = fbAppId;
        App.fbLanguage = fbLanguage;

        App.reset();
        App.run();
        Loading.end();

        $.smartbanner({
            title: "Rue des Vignerons : expériences vin et spiritueux",
            button: 'Voir',
            price: 'Gratuit',
            inGooglePlay: 'Sur le Play Store',
            author: '',
            onClose: function (e) {
                $(e.target).closest('#smartbanner').remove()
            }
        });
    },
    reset: function () {
        ViewHelpers.reset();
    },
    run: function () {
        if (parent && window.name === 'phonegap' && typeof parent.postMessage === 'function') {
            $('body').addClass('mobile-app');
        }
        Device.init();
        if (typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'] === 'object' && typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].init === 'function') {
            window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].init();
            if (typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'][App.action + 'Action'] === 'function') {
                window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'][App.action + 'Action']();
            }
        }
        ViewHelpers.init();
        MySwipers.init();
        $(window).resize(App.onResize);
        $(window).scroll(App.onScroll);
        $(document.body).on('touchmove', App.onScroll);
        $(document).on("mousewheel DOMMouseScroll wheel", App.onScroll);

        if (parent && window.name === 'phonegap' && typeof parent.postMessage === 'function') {
            parent.postMessage({action: 'app.loaded'}, '*');
            App.bindMobileAppEvents();
        }
    },
    gotoPage: function (url) {
        if (typeof PhoneApp == 'object') {
            PhoneApp.goto(url);
        }
        else {
            window.location.href = url;
        }
    },
    onResize: function () {
        var windowWidth = $(window).width();
        var windowHeight = $(window).height();

        if ($('body').hasClass('mobile-app')) {
            windowHeight = 500;
        }

        Device.onResize(windowWidth, windowHeight);
        // Trigger resize of view helpers
        ViewHelpers.onResize(windowWidth, windowHeight);
        // Trigger resize of view helpers
        if (typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'] === 'object' && typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].onResize === 'function') {
            window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].onResize(windowWidth, windowHeight);
        }
    },
    onScroll: function () {
        var scrollTop = $(window).scrollTop();
        // Trigger resize of view helpers
        ViewHelpers.onScroll(scrollTop);
        // Trigger resize of view helpers
        if (typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'] === 'object' && typeof window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].onScroll === 'function') {
            window[App.module.ucfirst() + '_' + App.controller.ucfirst() + 'Controller'].onScroll(scrollTop);
        }
    },
    bindMobileAppEvents: function () {
        // open external links on new window
        $(document).on('click', "a[target='_blank']", function (e) {
            e.preventDefault();
            parent.postMessage({action: 'window.open', args: [$(this).attr('href'), '_system', 'location=no']}, '*');
        });
    }
};
