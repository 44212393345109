var Layout_Favorites = {
    loginUrl: false,
    // Methods
    init: function () {
        $('body').on('click', 'span.layout-favorites-button', function (e) {
            e.preventDefault();
            var button = $(this);
            var favorites_type = button.data('favorites-type');
            var favorites_id = button.data('favorites-id');
            Layout_Favorites.loginUrl = button.data('login-url');

            var addUrl = '/index/add-to-favorites/?' + jQuery.param({
                    favorites_type: favorites_type,
                    favorites_id: favorites_id,
                    return_url: window.location.href
                });
            if ($('#content').hasClass('connected-1') || $('#content').hasClass('connected-partner-1')) {

                $.ajax({
                    cache: false,
                    url: addUrl,
                    success: function (data) {

                        if (data === 'ok-0') {
                            button.removeClass('ico-heart-hover');
                            button.addClass('ico-heart');
                            return;
                        }
                        if (data === 'ok-1') {
                            button.addClass('ico-heart-hover');
                            button.removeClass('ico-heart');
                            return;
                        }
                        if (data === 'login') {
                            Popin.open(Layout_Favorites.loginUrl, {'callback-js': 'Layout_Favorites.callback'})
                        }
                    }
                });
            }
            else {
                Popin.open(Layout_Favorites.loginUrl, {'callback-js': 'Layout_Favorites.callback', 'callback': addUrl});
            }

        });
    },

    callback: function (favorites_type, favorites_id) {
        alert('callback');
    },
    _login: function (favorites_type, favorites_id) {

    }
};
