var Accordion = {
  heading: undefined,
  init: function (heading) {
    this.heading = heading;

    return this
  },

  showOne: function () {
    var $this = this;
    (function($) {
      var accordionHeading = $($this.heading)
      accordionHeading.each(function() {
        $(this).on("click", function () {
          var item = $(this);
          if (item.hasClass('active')) {
            item.removeClass('active');
          } else {
            accordionHeading.each(function () {
              var element = $(this);
              element.hasClass('active') ?
                element.removeClass('active') : null;

              var arrow = $(element.find('.arrow')[0]);
              arrow.hasClass('up') ?
                arrow.removeClass('up') : null;
              arrow.addClass('down');
            });

            item.addClass('active');
          }

          var arrow = $(item.find('.arrow')[0]);
          if (arrow.hasClass('down')) {
            arrow.removeClass('down');
            arrow.addClass('up');
          } else {
            arrow.removeClass('up');
            arrow.addClass('down');
          }
        });
      });
    })(jQuery);
  }
};
