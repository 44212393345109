var GmapStaticLoader = {
    el: {
        container: '.gmap-container'
    },
    // Methods
    init: function () {
        var self = this;
        if ($(this.el.container).length == 0)
            return;

        GoogleMap.load('GmapStaticLoader.gmapLoaded');
    },
    gmapLoaded: function () {
        $(this.el.container).each(function (i, res) {
            new GmapStatic($(res).data('lat'), $(res).data('lng'), $(res).data('zoom'), $(res).find('.google-map'), $(res).data('ico'), $(res).find('.tooltip-container-hidden').html(),$(res).data('pan-target'));
        });
    }
};

var GmapStatic = function (lat, lng, zoom, el, ico, content, pan_target) {
    this.el = el;
    this.lat = lat;
    this.lng = lng;
    this.ico = ico;
    this.pan_target = pan_target;
    if(typeof zoom !== 'undefined')
        this.zoom = zoom;
    if(typeof content !== 'undefined')
        this.infoWindowContent = content;
    this.init();
};
GmapStatic.prototype.gmap = null;
GmapStatic.prototype.lat = null;
GmapStatic.prototype.pan_target = null;
GmapStatic.prototype.center = 0;
GmapStatic.prototype.old_pan_x = 0;
GmapStatic.prototype.ico = null;
GmapStatic.prototype.lng = null;
GmapStatic.prototype.zoom = 7;
GmapStatic.prototype.el = null;
GmapStatic.prototype.mainMarker = null;
GmapStatic.prototype.infoWindowContent = "";
GmapStatic.prototype.init = function () {
    var self = this;
    var mapOptions = {
        center: {lat: this.lat, lng: this.lng},
        zoom: 15,
        scrollwheel: false,
        navigationControl: true,
        mapTypeControl: false,
        scaleControl: false,
        draggable: true
    };
    this.gmap = new google.maps.Map($(this.el).get(0), mapOptions);

    $('body').on('touchstart',function(){
        self.gmap.setOptions({draggable:false});
    });

    var LatLngMarker = new google.maps.LatLng(this.lat, this.lng);
    var markerOptions = {
        position: LatLngMarker,
        map: this.gmap,
        animation: 2 // dropped
    }
    self.center = self.gmap.getCenter();

    if (typeof this.ico !== 'undefined') {
        markerOptions.icon = this.ico;
    }
    // add marker
    this.mainMarker = new google.maps.Marker(markerOptions);
    // add tooltip
    if(self.infoWindowContent !== ''){
        var infowindow = new google.maps.InfoWindow(
            {
                content : self.infoWindowContent
            }
        );
        infowindow.open(self.gmap, self.mainMarker);
        google.maps.event.addListener(infowindow, 'domready', function() {
            self.setCenter(-( $(self.el).find('.gm-style-iw').height()));
        });
    }
    // trigger on resize event
    google.maps.event.addDomListener(window, "resize", function() {

        google.maps.event.trigger(self.gmap, "resize");
        self.setCenter(-( $(self.el).find('.gm-style-iw').height()));
    });
};
GmapStatic.prototype.setCenter = function (y,x) {
    var self = this;

    if(typeof x == 'undefined'){
        if(self.pan_target && $(self.pan_target).width() != 0){
            x = $(self.pan_target).width()/2-$(self.el).find('.gm-style-iw').width();
        } else {
            x = 0;
        }
    }
    if(typeof y == 'undefined'){
        y = 0;
    }

    setTimeout(function(){
        if(x != 0 || y !=0 ){
            self.gmap.setCenter(self.center);
            self.gmap.panBy(x,y);
        }
    },'1000');
};