var Ajaxify = {
    el: {
        link: 'a.ajaxify',
        parentAjaxify: '.ajaxify-container'
    },
    // Methods
    init: function () {
        var self = this;
        $(document).on('click', self.el.link, function (e) {
            e.preventDefault();
            var $this = $(this);
            var xhr = self.getContent($(this).attr('href'), function (content) {
                if ($this.parents(self.el.parentAjaxify).length > 0) {
                    $this.parents(self.el.parentAjaxify).html(content);
                    Obfuscation.unobfuscate()
                }
            });
            xhr.done(function () {
                $(self.el.parentAjaxify).removeClass('loading');
            });
        });

        $('body').on('submit', 'form.ajaxify-loading', function () {
            var div = $('<div id="ajaxify-overlay"/>');
            div.html('<div class="ajaxify-container loading"/>');
            $('#content').append(div);
        });
    },
    getContent: function (url, cb) {
        $(this.el.parentAjaxify).addClass('loading');
        return $.ajax({
            url: url,
            cache: false,
            dataType: 'html',
            success: function (content) {
                if (typeof cb == 'function') {
                    cb(content);
                }
            }
        });
    }
};
