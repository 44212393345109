var Front_DomainesController = {
    // Methods
    nearestSliderLoaded: false,
    nearestSlider: null,
    init: function () {
        this.initFicheAction();

        // disables slider inside block layout domain
        // only if domain status is ended
        if(parseInt($('#isOnline').val()) == 0){
            $('.layout-domain-container .bxslider').addClass('loaded');
        }

        (function($) {
            $(window).load(function () {
                var splitParams = window.location.href.split('?');
                if (splitParams.length > 1) {
                    var search = splitParams[1].split('&');
                    if (search.length > 0) {
                        for (var i = 0; i < search.length; i++) {
                            var params = search[i].split('=');
                            if (params[0] === 'date') {
                                var date = new Date();
                                $('#date').val(date.toLocaleDateString('fr-FR')).closest('form').trigger('submit');
                            }
                        }
                    }
                }

                var selectedDate = $(".form-search-domain").find("[name='date']").val();
                var adultes = $(".form-search-domain").find("[name='nb_adultes']").val();
                var lang = $(".form-search-domain").find("[name='lang']").val();
                if ('' !== selectedDate && adultes !== '' && adultes !== "0" && adultes !== 0 && lang !== '') {
                    Activities.update(false);
                }
            });
        })(jQuery);

        $('#more-wines').on('click', function (e) {
            e.preventDefault();
            $(this).next('ul').removeClass('hidden');
            $(this).hide();
        });
        $('.wines-container .filter').on('click', function (e) {
            e.preventDefault();
            $('#more-wines').click();
        });

        $('body').on('click','main#content.controller-domaines .container-activity .main-block', function () {
            var href = $(this).closest('.container-activity').find('.sub-block .description a').attr('href')
            if (href !== undefined) {
                window.location = href;
            }
        });

        this.setNearestDomainsSlider();
        this.faqSnippetArrow();
        this.showServiceProvider();
    },
    initFicheAction: function () {
        if ($(".controller-domaines.action-fiche").length === 0) {
            return;
          }
      
          $(document).on("apply-filters-v2", (e) => {
            Activities.update(e.activitiesUpdateScrollToContainer);
          });
    },
    onScroll: function (scrollTop) {
    },
    onResize: function (windowWidth, windowHeight) {
    },
    faqSnippetArrow: function () {
        const accordion = Accordion.init('.schema-faq-question');
        accordion.showOne();
    },
    showServiceProvider: function () {
       $('.js-see-more').on('click', function (e){
           e.preventDefault();

           var $link = $(e.currentTarget);
           var $icon = $link.find('i');
           if($icon.hasClass('up')) {
               $icon.removeClass('up');
               $icon.addClass('down')
           } else {
               $icon.removeClass('down');
               $icon.addClass('up')
           }

           if($link.hasClass('open')){
               $link.removeClass('open');
               $link.find('.text').text($link.data('closed-text'))
               $($link.data('target')).hide();
           } else {
               $link.addClass('open');
               $link.find('.text').text($link.data('opened-text'))
               $($link.data('target')).show();
           }
       });
    },
    setNearestDomainsSlider: function () {
        if ($('.container-nearest-domains .layout-domain-container').size() == 0 || this.nearestSliderLoaded == true)
            return;

        var config = {
            pager: false,
            maxSlides: 3,
            slideWidth: $('.container-nearest-domains > .layout-domain-container').outerWidth(),
            hideControlOnEnd : true,
            infiniteLoop : false,
            moveSlides : 1,
            onSlideAfter: function($slideElement, oldIndex, newIndex){
                if(newIndex !== 0){
                    $('.block-nearest-domains > .bx-wrapper .bx-prev').addClass('enabled');
                }
            }
        };


        this.nearestSliderLoaded = true;
        this.nearestSlider = $('.container-nearest-domains').bxSlider(config);
    },
};
