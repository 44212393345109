var CssRadioCheckbox = {
    // Methods
    init: function () {
        CssRadioCheckbox.parseAll();
    },
    parseAll: function () {
        $('input.css-checkbox:not(.css-checkbox-ready)').each(function () {
            $('<span class="css-checkbox"/>').insertAfter($(this).addClass('css-checkbox-ready'));
        });

        $('input.css-radio:not(.css-radio-ready)').each(function () {
            $('<span class="css-radio"/>').insertAfter($(this).addClass('css-radio-ready'));
        });
    }
};
