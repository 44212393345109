var Loading = {

    // Methods
    init: function () {
        $('body').on('click', "button:not(.no-loading),a[href]:not(.no-loading,.bx-next,.bx-prev)", function (e) {
            if ($(this).attr('href') && $(this).attr('href').substring(0, 1) !== '#' && $(this).attr('target') !== '_blank') {
                if (typeof PhoneApp == 'object') {
                    e.preventDefault();
                    PhoneApp.goto($(this).attr('href'));
                }
                Loading.start();
            }
        });
        $(document).ajaxStart(function () {
            Loading.start();
        });
        $(document).ajaxComplete(function () {
            Loading.end();
        });
        $(window).blur(function () {
            // Put your blur logic here
            Loading.end();
        });
    },
    start: function () {
        $('#loading-barre').removeClass('loaded');
        if ($('body').hasClass('mobile-app'))
            parent.postMessage({action: 'app.unload'}, '*');
    },
    end: function () {
        $('#loading-barre').addClass('loaded');
    }
};
