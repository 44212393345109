var FormSearchDomainMobile = {
    init: function () {
        var self = this;
        self.initClearableFilters();
        self.afterDomChange();
        self.initForm();

    },
    initForm:function (){
        $('.form-search-mobiledomain').on('submit', function (e){
            e.preventDefault();

            $('.form-search-headersearch #lang').val($('#lang_mobile').val());
            $('.form-search-headersearch #nb_adultes').val($('#nb_adultes_mobile').val());
            $('.form-search-headersearch #date').val($('#date_mobile').val());
            $('#order').val($('#order_mobile').val())

            setTimeout(function (){
                $('form.form-search').first().trigger('submit');
            });

            return false;
        })
    },
    initClearableFilters: function () {
        $('body').on('change', '.form-search-mobiledomain .clearable-filter input, .form-search-mobiledomain .clearable-filter select', function (e) {
            if (e.originalEvent) {
                $(this).closest('form').trigger('submit');
            }
        })

        $('body').on('click', '.form-search-mobiledomain .js-embedded-select .clear-filter', function () {
            var $select = $(this).parent().find('select');
            setTimeout(function (){
                $select.closest('form').trigger('submit');
            })
        })

        $('body').on('click', '.form-search-mobiledomain .js-embedded-input .clear-filter', function () {
            var $input = $(this).parent().find('input');
            setTimeout(function (){
                $input.closest('form').submit();
            })
        })
    },
    afterDomChange: function () {
        var self = this;
        $('.form-search-mobiledomain input#date_mobile').datetimepicker({
            lang: $('#CURRENT_LANG').val(),
            timepicker: false,
            format: 'd/m/Y',
            scrollInput: false,
            scrollMonth: false,
            scrollTime: false,
            minDate: '0',
            dayOfWeekStart: 1,
            closeOnDateSelect: true,
            onGenerate: function (ct) {
                $('.xdsoft_datetimepicker ')
                    .addClass('search-datetimepicker');
            },
            onSelectDate: function (time, $input){
                $input.closest('form').submit();
            }
        });

        $('.js-embedded-select select').trigger('change');
        $('.js-embedded-input input').trigger('change');

        self.initForm();
    }
};
