var CodePostalAutocomplete = {
    field: null,
    // Methods
    init: function () {

        this.fields = $('input[data-autocomplete-city]');
        if (this.fields.size()) {
        }
        GoogleMap.load('CodePostalAutocomplete.googleMapEvents');
    },
    googleMapEvents: function () {

        this.fields.each(function () {
            var field_cp = $(this);
            var field_ville = $('#' + field_cp.data('autocomplete-city'));
            CodePostalAutocomplete.autocompleteVille(field_cp, field_ville);
        });
    },
    autocompleteVille: function (field_cp, field_ville) {
        if (field_cp.length > 0 && field_ville.length > 0) {
            field_cp.on('keyup', function () {
                if ($(this).val().length == $(this).attr('maxlength')) {
                    var geocoder = new google.maps.Geocoder();
                    var code = $(this).val();
                    geocoder.geocode({'address': code + ' ,FRANCE'}, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            field_ville.empty();
                            if (results[0].postcode_localities) {
                                $.each(results[0].postcode_localities, function (i, localite) {
                                    field_ville.append($('<option>', {value: localite, text: localite}));
                                });
                            } else {
                                field_ville.append($('<option>', {
                                    value: results[0].address_components[1].long_name,
                                    text: results[0].address_components[1].long_name
                                }));
                            }
                            field_ville.removeAttr('disabled');
                            field_ville.val(results[0].address_components[1].long_name).change();
                        }
                    });
                } else {
                    field_ville.empty();
                    field_ville.select2({ allowClear: true });
                    field_ville.attr('disabled','disabled');
                }
            });
        }
    }
};
