var GoogleMap = {
    // Properties
    mapElement: false,
    debug: true,
    map: false,
    loaded: false,
    callbackName: null,
    stackToCall: [],

    // Methods
    load: function (callback) {
        if (!callback) callback = "GoogleMap.initialize" + App.module.ucfirst();

        if (this.loaded) {
            var funcCall = callback + "();";
            eval(funcCall);
            return;
        }

        this.stackToCall.push(callback);

        GoogleMap.mapElement = $(".google-map");
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src =
                "//maps.googleapis.com/maps/api/js?&sv=3.44&callback=GoogleMap.callback&key=" +
                GOOGLE_API_KEY;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "gmaps");
    },

    /**
     * Ajout d'un marker
     * @param x latitude
     * @param y longitude
     * @param title (optionnel)
     * @param map (optionnel)
     * @param color (optionnel, blue/red/...)
     */
    addMarker: function (x, y, title, map, color, zIndex) {
        if (!color) color = "blue";
        if (!map) map = GoogleMap.map;
        new google.maps.Marker({
            position: new google.maps.LatLng(x, y),
            map: map,
            title: title,
            zIndex: zIndex,
            icon:
                "https://maps.gstatic.com/mapfiles/ms2/micons/" +
                color +
                "-dot.png",
        });
    },

    initializeFront: function () {
        var mapOptions = {
            center: { lat: -25.397, lng: 131.644 },
            zoom: 8,
        };
        GoogleMap.map = new google.maps.Map(
            GoogleMap.mapElement.get(0),
            mapOptions
        );

        var markers = [];
        // To add the marker to the map, use the 'map' property
        markers.push(
            new google.maps.Marker({
                position: new google.maps.LatLng(-25.363882, 131.044922),
                title: "Hello World!",
            })
        );

        // To add the marker to the map, use the 'map' property
        markers.push(
            new google.maps.Marker({
                position: new google.maps.LatLng(-26.063882, 130.944922),
                title: "Hello World 2!",
            })
        );
        // To add the marker to the map, use the 'map' property
        markers.push(
            new google.maps.Marker({
                position: new google.maps.LatLng(-24.063882, 131.944922),
                title: "Hello World 3!",
            })
        );
        mc = new MarkerClusterer(GoogleMap.map, markers);
    },
    reCenter: function (latlng, offsetx, offsety, map) {
        var point1 = map
            .getProjection()
            .fromLatLngToPoint(
                latlng instanceof google.maps.LatLng ? latlng : map.getCenter()
            );
        var point2 = new google.maps.Point(
            (typeof offsetx == "number" ? offsetx : 0) /
                Math.pow(2, map.getZoom()) || 0,
            (typeof offsety == "number" ? offsety : 0) /
                Math.pow(2, map.getZoom()) || 0
        );
        map.setCenter(
            map
                .getProjection()
                .fromPointToLatLng(
                    new google.maps.Point(
                        point1.x - point2.x,
                        point1.y + point2.y
                    )
                )
        );
        return map;
    },
    callback: function () {
        this.loaded = true;
        for (var i = 0; i < this.stackToCall.length; i++) {
            var callback = this.stackToCall[i];
            var funcCall = callback + "();";
            eval(funcCall);
        }
        this.stackToCall = [];
    },
};
