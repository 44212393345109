var FullscreenSlider = {

    loaded       : false,
    sliders      : [],
    // Methods
    init         : function () {
        FullscreenSlider.runSliders();
    },
    runSliders   : function () {
        if(FullscreenSlider.loaded){
            FullscreenSlider._run();
        } else {
            $(window).load(function(){
                FullscreenSlider.loaded = true;
                FullscreenSlider._run();
            });
        }
    },
    _run         : function () {
        var self = this;
        $('.fullscreen-slider-super-container .bxslider').each(function () {
            var element = $(this);
            if (element.children().size() > 1) {
                if(element.hasClass('loaded'))
                    return;

                element.addClass('loaded');
                self.sliders.push(element.bxSlider({pager: false}));
            }
        })
    },
    reloadSliders: function () {
        var self = this;
        $('.fullscreen-slider-super-container .bxslider').each(function () {
            for (var key in self.sliders) {
                if (self.sliders.hasOwnProperty(key)) {
                    self.sliders[key].reloadSlider();
                }
            }
        })
    }
};
