var Alertify = {
    type: null,
    url: null,
    // Methods
    init: function () {
        if ($("#alertify-url").length == 0)
            return;

        this.url = $("#alertify-url").val();
        this.type = $("#alertify-type").val();

        Popin._createPopin();

        if (this.type !== 'secondary') {
            Popin.popupElement.addClass('exit-by-btn');
        }
        Popin.open(this.url);
    },
};
