var FilteredContent = {
    el : {
        container : '.excerpt-container'
    },
    // Methods
    init: function () {
        var self = this;
        $('body').on('click','.filtered-content-container .filter',function(e){
            e.preventDefault();
            var $parent = $(this).parents(".filtered-content-container");
            $parent.find('.active').removeClass('active');
            $(this).parent('li').addClass('active');

            var filterVal = $(this).data('filter-val');
            self.update($parent,filterVal);
        });
    },
    update : function($parent,filterVal){
        if(typeof filterVal == 'undefined'){
            filterVal = $parent.find('.active a').data('filter-val');
        }
        $.each($parent.find('.filtered-content'),function(i,res){
            if(filterVal == 'all' || $(res).data('content-type') == filterVal){
                $(res).show();
            } else {
                $(res).hide();
            }
        });
    }
};
