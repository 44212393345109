var Front_IntroPartnersController = {

    presentationTimeout: false,
    presentationSlider: false,
    iconsSlider: [],
    // Methods
    init: function () {
    },
    presentationAction: function () {
        Front_IntroPartnersController.initPresentationSlider();
    },
    initPresentationSlider: function () {
        if (!Device.isTabletteXS) {
            // Desktop
            if (Front_IntroPartnersController.presentationSlider) {
                Front_IntroPartnersController.presentationSlider.destroySlider();
                Front_IntroPartnersController.presentationSlider = false;
            }
        }
        else {
            // Tablette
            if (!Front_IntroPartnersController.presentationSlider) {
                Front_IntroPartnersController.presentationSlider = $('.module-front.controller-intro-partners .bloc-top .icons').bxSlider({
                    controls: false
                });
            }
        }
    },
    onResize: function (windowWidth, windowHeight) {
        if (App.action == 'presentation') {
            if (Front_IntroPartnersController.presentationTimeout)
                clearTimeout(Front_IntroPartnersController.presentationTimeout);
            Front_IntroPartnersController.presentationTimeout = setTimeout(Front_IntroPartnersController.initPresentationSlider, 1000);
        }
    }
};
