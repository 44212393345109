var GoogleMapForm = {
    init : function(){
        // load Google map
        if ($('input.google-map').size()){
            GoogleMap.load('GoogleMapForm.run');
        }
    },
    run : function(){

        var map = false;
        var inputCount = $('input.google-map').size();

        $('input.google-map').each(function () {
            var value = $(this).val();
            var input = $(this);
            var pos = {lat: 46.10495433786921, lng: 2.4722470703125055};
            var zoom = 9;

            if (value !== '' && value.split(',').length >= 2) {
                pos = value.split(',');
                if (input.hasClass('google-map-no-zoomsave'))
                    zoom = 10;
                else
                    zoom = parseInt(pos[2]);
                pos = {lat: parseFloat(pos[0]), lng: parseFloat(pos[1])};
            }

            if (map === false) {
                var div = $('<div class="google-map"></div>');
                div.insertAfter($('input.google-map').last());
                div.append('<div/>');
                map = new google.maps.Map(div.find('div').get(0));
            }
            map.setZoom(zoom);

            var mapField = input.data('map-field');
            if (typeof mapField === 'undefined') {
                mapField = false;
            }
            else {
                mapField = $(mapField);
                if(!input.data('hide-detect-button')){
                    var btn = $('<a class="btn btn-primary btn-sm">Détecter automatiquement</a>');
                    btn.insertAfter(input);

                    btn.click(function (e) {
                        e.preventDefault();
                        var defaultValue = btn.text();
                        btn.html(btn + ' (recherche en cours)');
                        geocode(function(){
                            btn.html(defaultValue);
                        });
                    });
                }
            }


            map.setCenter(pos);
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(pos.lat, pos.lng),
                map: map,
                draggable: true
            });

            var geocode = function(callback){
                var geocoder = new google.maps.Geocoder();

                geocoder.geocode({'address': mapField.val() + ', FRANCE'}, function (results, status) {
                    if (status == google.maps.GeocoderStatus.OK) {
                        var position = results[0].geometry.location;
                        marker.setPosition(position);
                        map.setCenter(position);
                        updateInputValue();

                    } else {
                        if(App.module == 'admin')
                            alert('Geocode was not successful for the following reason: ' + status);
                        else
                            console.error('Geocode error : '+status);
                    }
                    if(typeof callback == 'function'){
                        callback();
                    }
                });
            };

            if(App.module != 'admin' && mapField !== false){
                $(mapField).on('change',function(){
                   if($(this).val().length > 2){
                       geocode();
                   }
                });

            }


            var updateInputValue = function () {

                var position = marker.getPosition();
                var finalValue = position.lat() + ',' + position.lng();
                if (!input.hasClass('google-map-no-zoomsave'))
                    finalValue += ',' + map.getZoom();
                input.val(finalValue);
                return position;
            };

            google.maps.event.addListener(marker, 'dragend', function () {
                if (inputCount > 1)
                    updateInputValue();
                else
                    map.setCenter(updateInputValue());
            });

            google.maps.event.addListener(map, 'zoom_changed', function () {
                updateInputValue();
            });

            google.maps.event.addListener(marker, 'drag', function () {
                updateInputValue();
            });

        });
    }
}
