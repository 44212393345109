var MySwipers = {
    buildSwiper: function (containerId, size, options) {
        if (Swiper === undefined) {
            console.error("The swiper library is not loaded");
            return;
        }
        var sliderContainerSelector = "#" + containerId + ".slider-container";
        var pepiteAutoSliderBreakpoint = 1000;
        var isPepiteSwiper =
            size === "pepite" && window.innerWidth < pepiteAutoSliderBreakpoint;
        var shouldSwiper = size !== "pepite" || isPepiteSwiper;
        return shouldSwiper
            ? new Swiper(
                  sliderContainerSelector + " .swiper",
                  Object.assign(
                      Object.assign(
                          {
                              // Optional parameters
                              direction: "horizontal",
                              loop: false,
                              spaceBetween: 16,
                              spaceBefore: 16,
                              spaceAfter: 16,
                              watchOverflow: true,

                              // Navigation arrows
                              navigation: {
                                  nextEl:
                                      sliderContainerSelector +
                                      " .swiper-button-next",
                                  prevEl:
                                      sliderContainerSelector +
                                      " .swiper-button-prev",
                              },
                          },
                          typeof SWIPER_CONFIGS[size] === "function"
                              ? SWIPER_CONFIGS[size](
                                    sliderContainerSelector,
                                    size
                                )
                              : SWIPER_CONFIGS[size]
                      ),
                      options
                  )
              )
            : null;
    },

    init: function () {
        var self = this;
        $(function () {
            $(".slider-container").each(function (index, elem) {
                var options = {};
                var size = $(elem).data("size") || "md";
                self.buildSwiper(elem.id, size, options);
                console.log("swiper ", elem.id);
                $(elem).attr("data-initialized", true);
            });
        });
    },
};
