var Vignerons_CalendarController = {
    resizeTimeout: false,
    init: function () {
        Vignerons_CalendarController._createCalendar();

        $('body').on('click', 'form.calendar-header input[type=checkbox]', function () {
            Vignerons_CalendarController._reloadCalendar();
        });
        $('body').on('click', ".module-vignerons.controller-calendar a[href='#more-info']", function (e) {
            e.preventDefault();
            if ($('#more-info').is(':visible'))
                $('#more-info').hide();
            else
                $('#more-info').show();
        });

        $('body').on('click', '.module-vignerons.controller-calendar.action-view .reservations-lists a.show-more', function () {
            $('.reservations-lists').toggleClass('open');
        });

        $('body').on('click', '.module-vignerons.controller-calendar.action-view a.signaler-ecart', function (e) {
            e.preventDefault();
            $(this).parent().find('a.input-block').hide();
            $('form.form-nb-adultes').show();
        });

        $('body').on('click', '.module-vignerons.controller-calendar.action-view a.validation-giftcard', function (e) {
            e.preventDefault();
            $(this).parent().find('a.input-block').hide();
            $('form.form-giftcard').show();
            $("#giftcard_code").focus();
        });

        $('body').on('change', '.action-close-day #is_all_day', function (e) {
            var checked = $(e.currentTarget).prop('checked');
            if (checked === true) {
                $('.action-close-day .time-select-input').prop('disabled', true);
            } else {
                $('.action-close-day .time-select-input').prop('disabled', false);
            }
        });

        $('body').on('submit', 'form.form-close-day', function (e) {
            e.preventDefault();
            $(this).find('.btn-close-slots').prop('disabled', true).addClass('btn-disabled');
            Popin.open(
                $(this).attr("action"),
                $(this).serializeArray(),
                "POST",
                function () {
                    $(".time-select-input").select2();
                    $(".time-select-input").val('').trigger('change');
                }
            );
        });

        $('body').on('click', '.js-close-day-btn', function (e) {
            e.preventDefault();
            var link = $(e.currentTarget);
            Popin.open(link.attr('href'), {day: link.data('day')}, "GET", function () {
                $(".time-select-input").select2();
                $(".time-select-input").val('').trigger('change');
            });
        });

        $('#select-all').on('click', function () {
            var isCheck = false;
            if($(this).is(":checked")) {
                isCheck = true;
            }
            $('input[name="activity[]"]').each(function () {
                this.checked = isCheck;
            })
        });

        $('input[name="activity[]"]').change(function () {
            var countChecked = $('input[name="activity[]"]:checked').length;
            var countAll = $('input[name="activity[]"]').length;
            var selectAll = document.getElementById('select-all');

            selectAll.checked = countAll === countChecked;
        });

        $('body').on('submit', 'form.form-cancel-slot', function (e) {
            e.preventDefault();
            $(this).find('.btn-cancel-slot').prop('disabled', true).addClass('btn-disabled');
            Popin.open(
                $(this).attr("action"),
                $(this).serializeArray(),
                $(this).attr("method")
            );
        });

        if (enableCalendarTools) {
            $('.calendar-header .activity').draggable({
                revert: true,
                helper: "clone",
                cursorAt: {top: $('.calendar-header .activity').height() / 2, left: 30}
            });
        }

        $('body').on('click', 'a.btn-lock-slot,a.btn-unlock-slot', function (e) {
            e.preventDefault();

            var showConfirm = false;
            var confirmMsg = $(e.target).data('custom_confirm');
            if (typeof confirmMsg !== 'undefined') {
                showConfirm = true;
            }
            if (showConfirm) {
                var confirmation = window.confirm(confirmMsg)
                if (confirmation) {
                    Popin.open($(this).attr('href'), undefined, undefined, function () {
                        Vignerons_CalendarController.refetchEvents();
                    });
                }
            } else {
                Popin.open($(this).attr('href'), undefined, undefined, function () {
                    Vignerons_CalendarController.refetchEvents();
                });
            }
        });

        $('body').on('submit', '#Vignerons_Form_UpdateSlot', function () {
            var $form = $(this);
            $form.find('.submit').prop('disabled', true).addClass('btn-disabled');
        });

        $('body').on('change', '#Vignerons_Form_UpdateSlot #mode', function (e) {
            var newValue = $(e.currentTarget).val();
            var $langField = $("#Vignerons_Form_UpdateSlot #lang");
            var $langsField = $("#Vignerons_Form_UpdateSlot #langs");

            if (newValue === 'monolingual-dynamic' || newValue === 'multilingual') {
                $langField.closest(".form-group").addClass('invisible-field');
                $langsField.closest(".form-group").removeClass('invisible-field');
            }

            if (newValue === 'monolingual-fixed') {
                $langField.closest(".form-group").removeClass('invisible-field');
                $langsField.closest(".form-group").addClass('invisible-field');
            }
        })
    },
    refetchEvents: function () {
        $('#calendar').fullCalendar('refetchEvents');
    },
    _reloadCalendar: function () {
        var divCalendar = $('#calendar');
        $('body').css('min-height', $('body').height() + 'px');
        var defaultDate = divCalendar.fullCalendar('getDate');
        divCalendar.fullCalendar('destroy');
        Vignerons_CalendarController._createCalendar(defaultDate);

        $('body').css('min-height', '');
    },
    onResize: function (windowWidth, windowHeight) {
        var self = Vignerons_CalendarController;
        clearTimeout(self.resizeTimeout);
        self.resizeTimeout = setTimeout(function () {
            var divCalendar = $('#calendar');
            divCalendar.fullCalendar('changeView', self.getView());
        }, 600);
    },
    getView: function () {
        return $(window).width() > 600 ? 'agendaWeek' : 'agendaDay'
    },
    _createCalendar: function (defaultDate) {
        var self = Vignerons_CalendarController;
        var divCalendar = $('#calendar');
        if (defaultDate === undefined && divCalendar.data('currentdate') !== undefined) {
            defaultDate = divCalendar.data('currentdate');
        }
        var $picker = $('<input type="button" id="cal_datepciker" value="Date" class="fc-next-button fc-button fc-state-default fc-corner-right">')
        setTimeout(() => {
            divCalendar.fullCalendar({
                header: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'agendaWeek,agendaDay'
                },
                droppable: true,
                drop: function (date, jsEvent, ui) {
                    var date = date.toISOString();
                    var url = $(ui.helper).data('url');
                    Popin.open(url, {date: date}, 'GET', function () {
                        $('.front-datetimepicker').datetimepicker({
                            lang: $('#CURRENT_LANG').val(),
                            format: 'd/m/Y H:i',
                            scrollInput: false,
                            dayOfWeekStart: 1,
                            closeOnDateSelect: true,
                            onGenerate: function (ct) {
                                // $('.xdsoft_datetimepicker ')
                                //   .addClass('search-datetimepicker');
                            }
                        });
                    });
                },
                defaultDate: defaultDate,
                defaultView: self.getView(),
                allDaySlot: false,
                contentHeight: 'auto',
                scrollTime: divCalendar.data('start_hour'),
                minTime: divCalendar.data('start_hour'),
                //events: divCalendar.attr('data-events'),
                eventSources: [
                    {
                        url: divCalendar.attr('data-events'),
                        type: 'POST',
                        cache: false,
                        data: function () {
                            var array = $('form.calendar-header').serialize();

                            return {filters: array};
                        }
                    }
                ],
                hiddenDays: (function () {
                    if (!$('#worked-days').is(':checked')) {
                        return [];
                    }
                    if (typeof divCalendar.data('unworkingdays') == 'undefined') {
                        return [];
                    }
                    var k = $.map((divCalendar.data('unworkingdays') + '').split(','), function (value) {
                        return parseInt(value, 10);
                    });
                    return k;
                })(),
                editable: false,
                eventLimit: true,
                slotEventOverlap: false,
                eventRender: function (event, element) {
                    if (enableCalendarTools) {
                        var th = $('.fc-day-header.fc-' + event.dayName + '');
                        if (!th.find('select').size()) {
                            Vignerons_CalendarController._createSelect(th, event);
                            Vignerons_CalendarController._addButtonClose(th, event.day);
                        }
                        Vignerons_CalendarController._addFlag(event, element);
                    }
                },
                viewRender: function (view, element) {
                    $picker.val(view.start.format('DD/MM/YYYY'))
                    if (typeof window.history.replaceState === "function") {
                        var currentUrl = new URL(window.location.href);
                        currentUrl.searchParams.set('currentDate', view.start.format('YYYY-MM-DD'))
                        window.history.replaceState(
                            {},
                            currentUrl.href,
                            currentUrl.href,
                        );
                    }
                }
            });

            $picker.appendTo($('.fc-left .fc-button-group'))
            $picker
                .datetimepicker({
                    lang: 'fr',
                    timepicker: false,
                    format: 'd/m/Y',
                    scrollInput: false,
                    closeOnDateSelect: true,
                    dayOfWeekStart: 1,
                    onGenerate: function (ct) {
                        $('.xdsoft_datetimepicker ').addClass('search-datetimepicker');
                    },
                    onSelectDate: function (time, $input) {
                        divCalendar.fullCalendar('gotoDate', time);
                    }
                });
        }, 5);
    },
    _addFlag: function (event, element) {
        var twoLineMinHeight = 20;
        setTimeout(function () {
            // If event has a lang (=flag)
            if (event.flag) {
                // Get element height
                var height = $(element).height();
                // Detect if event is full-width
                var isFullWidth = parseInt($(element).css('left')) === 0 && parseInt($(element).css('right')) === 0;
                // If element isn't full-width and can be 2 lines
                if (!isFullWidth && height > twoLineMinHeight) {
                    // Add class
                    element.addClass('two-line-flag');
                }
                // Add img flag
                element.prepend('<img src="' + event.flag + '" class="flag"/>');
            }
        }, 100);
    },
    _addButtonClose: function (th, day) {
        var closeLink = $('<a class="js-close-day-btn close-btn" href="#"><i class="icon icon-minus-3"></i> Fermer</a>');
        closeLink.attr('href', $('#calendar').data('closedayurl'))
        closeLink.data('day', day);
        $(th).append(closeLink);
    },
    _createSelect: function (th, event) {
        var select = $('<select class="select2 select2-loaded select-style select-sm"></select>');
        for (var num = 1; num <= 20; num++) {
            select.append('<option value="' + num + '">' + num + ' act. simul.</option>');
        }
        select.val(event.nb_activity_max);
        th.append('<div style="margin-top: 4px;"/>');
        th.append(select);
        select.select2();
        select.change(function () {
            if (window.confirm(i18nCalendar.confirmSelectChange)) {
                $.ajax({
                    cache: false,
                    data: {nb: $(this).val()},
                    url: event.updateMaxUrl,
                    success: function (data) {
                        Vignerons_CalendarController._reloadCalendar();
                    }
                });
            } else {
                Vignerons_CalendarController._reloadCalendar();
            }
        });
    }
};
