var Layout_MenuMobile = {
  menu_mobile: null,
  open_buttons: [],
  nav: null,
  close_buttons: [],
  links: null,
  slider: null,
  slides: null,
  back_buttons: null,
  speed_animation: 500,
  current_path: [0],
  menu_width: 0,
  menu_height: 0,
  current_deepness: 0,
  body_class: "menu_mobile_displayed",
  height_header: 0,
  init: function () {
    var self = this;
    this.open_buttons.push($("#header .menu-btn-desktop"))
    this.nav = $("#nav_mobile");
    this.close_buttons.push(this.nav.find(".sandwich"));
    this.close_buttons.push(this.nav.find(".close-button"));
    this.links = this.nav.find("li a, li span");
    this.slider = this.nav.find(".slider");
    this.slides = this.slider.find(".slide");
    this.back_buttons = this.nav.find(".back_button");
    this.height_header = this.slides.find("header").first().outerHeight(true);
    this.setSize();
    this.watchSandwiches();
    this.watchLinks();
    this.watchBackButtons();
    this.watchResize();
  },
  watchSandwiches: function () {
    var self = this;
    this.open_buttons.forEach(function(button){
        button.click(function (e) {
            e.preventDefault();
            if (self.nav.hasClass("shown")) {
              self.nav.removeClass("shown");
              $("body").removeClass(self.body_class);
            } else {
              self.nav.addClass("shown");
              $("body").addClass(self.body_class);
            }
          });
    })
    this.close_buttons.forEach(function(button){
        button.click(function (e) {
            e.preventDefault();
            self.nav.removeClass("shown");
            $("body").removeClass(self.body_class);
        });
    })
  },
  watchLinks: function () {
    var self = this;
    this.links.click(function (e) {
      var $self = $(this);
      if ($self.attr("href") === undefined) {
        e.preventDefault();
        var id = $self.attr("data-id");
        self.slide("left", id);
      }
    });
  },
  watchBackButtons: function () {
    var self = this;
    this.back_buttons.click(function (e) {
      e.preventDefault();
      self.slide("right");
    });
  },
  watchResize: function () {
    var self = this;
    $(window).resize($.proxy(self.setSize, self));
  },
  slide: function (direction, id) {
    var self = this;
    if (direction == "left") {
      if ($.inArray(id, self.current_path) === -1) {
        self.current_deepness++;
        self.links.filter("[data-id_parent='" + id + "']").show();
        self.slides
          .filter(":gt(" + (self.current_path.length - 1) + ")")
          .find("li a, li span")
          .not("[data-id_parent='" + id + "']")
          .hide();
        self.current_path.push(id);
        self.slider.css("left", Math.max(-self.menu_width * (self.current_deepness), -self.current_deepness * self.menu_width) + "px");
      }
    } else {
      self.current_deepness--;
      self.current_path.pop();
      self.slider.css("left", Math.min(0, -self.current_deepness * self.menu_width) + "px");
    }
  },
    setSize: function () {
        var self = this;
        self.menu_width = Math.min($(window).width(), 600);
        self.menu_height = $(window).height();
        self.slider.css("left", -self.current_deepness * self.menu_width + "px");
        self.slides.find("ul").css("height", (self.menu_height - self.height_header) + "px");
    }
};
