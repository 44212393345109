var Pepites = {

    element: false,
    isOpen: false,

    // Methods
    reset: function () {
        Pepites.isOpen = false;
        Pepites.element = false;
    },
    init: function () {
        Pepites.element = document.getElementById('pepites');
        if (Pepites.element) {
            Pepites.element = $(Pepites.element);
            Pepites.element.find('a.close').click(function (e) {
                e.preventDefault();
                Pepites.close();
            });

            var button = $("a[href='#pepites']").click(function (e) {
                e.preventDefault();
                Pepites.open();
            });
            if (window.location.hash == '#pepites')
                button.trigger('click');
        }
    },
    open: function () {
        Pepites.isOpen = true;
        var pepiteHeight = Pepites.element.get(0).offsetHeight;
        $('#content').get(0).style.cssText = CSS.translate3d(0, pepiteHeight + 'px', 0);
        $('html,body').animate({
            scrollTop: 0
        }, 600);
        Front_IndexController.moveBackground(($(window).scrollTop() - pepiteHeight) / 4, 1000);
    },
    close: function () {
        Pepites.isOpen = false;
        $('#content').get(0).style.cssText = CSS.translate3d(0, 0, 0);
        Front_IndexController.moveBackground($(window).scrollTop() / 4, 1000);
    },
    onResize: function () {
        if (Pepites.isOpen) {
            setTimeout(Pepites.open, 100);
            setTimeout(Pepites.open, 1000);
        }
    }
};
