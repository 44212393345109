var FormRangeSlider = {
    // Methods
    init: function () {
        var self = this;
        $('.form-range').each(function () {

            if($(this).hasClass('loaded'))
                return;

            $(this).addClass('loaded');

            var rangeSlider = $(this).find('.range-slider');
            var inputMin = $(this).find('input').first();
            var inputMax = $(this).find('input').last();
            var min = parseInt(inputMin.data('min'));
            var max = parseInt(inputMax.data('max'));
            if (inputMin.val() == '')
                inputMin.val(min + ' ' + inputMin.data('suffix'));
            if (inputMax.val() == '')
                inputMax.val(max + ' ' + inputMin.data('suffix'));

            rangeSlider.noUiSlider({
                start: [Math.max(min, parseInt(inputMin.val())), Math.min(max, parseInt(inputMax.val()))],
                step: 1,
                range: {
                    'min': [min],
                    'max': [max]
                }
            });
            rangeSlider.on({
                slide: function () {
                    rangeSlider.parents('.form-element').addClass('edited');
                }
            });
            $(this).find('input').focus(function () {
                $(this).val(parseInt($(this).val()));
            });
            $(this).find('input').blur(function () {
                $(this).val(parseInt($(this).val()) + ' ' + inputMin.data('suffix'));
            });
            rangeSlider.Link('lower').to(inputMin, null, {
                to: function (data) {
                    return Math.round(data) + ' ' + inputMin.data('suffix');
                },
                from: Number
            });
            rangeSlider.Link('upper').to(inputMax, null, {
                to: function (data) {
                    return Math.round(data) + ' ' + inputMax.data('suffix');
                },
                from: Number
            });
        });
    }
};
