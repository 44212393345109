var FormMultiCheckboxElement = {
    // Methods
    init: function () {
        var prevent = false;
        $('body').on('click touchstart', '.form-multi-checkbox-element .form-element-inner > dt label', function () {
            if (prevent)
                return;
            prevent = true;
            setTimeout(function () {
                prevent = false;
            }, 500);
            var formElement = $(this).parents('.form-element');
            if (formElement.hasClass('open') === false) {
                formElement.parents('form').find('.form-element').removeClass('open');
            }
            formElement.toggleClass('open');
        });
        $('body').on('click', '.form-multi-checkbox-element a.close-btn', function (e) {
            e.preventDefault();
            var formElement = $(this).parents('.form-element');
            formElement.removeClass('open');
        });
        $('body').on('change', '.form-multi-checkbox-element input', function () {
            var formElement = $(this).parents('.form-element');
            formElement.find('a.close-btn').hide();
            formElement.find('input.submit').show();
        });
//        $('body').on('click', '.form-multi-checkbox-element input[type=checkbox],.form-multi-checkbox-element input[type=radio]', function () {
//            var formElement = $(this).parents('.form-element');
//            formElement.find('a.close-btn').hide();
//            formElement.find('input.submit').show();
//        });
        $('.form-element:has(div.filter)').each(function () {
            var element = $(this);
            element.find('input.filter').keyup(function () {
                var value = $(this).val().toLowerCase().latinize();
                element.find('.dropdown label').each(function () {
                    var text = $(this).text().toLowerCase().latinize();
                    if (text.indexOf(value) !== -1) {
                        $(this).show();
                    }
                    else {
                        $(this).hide();
                    }
                });
            });
        });
    }
};
