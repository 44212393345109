var HeaderFiltersTitle = {
    // Methods
    init: function () {
        $('body').on('click', '.header-filters-title .filters-list li a', function () {
            $(this).parents('.filters-list').find('.active').removeClass('active');
            $(this).parent().addClass('active');
            HeaderFiltersTitle._moveBar($(this).parents('.filters-list'));
        });
        $('body').on('mouseover', '.header-filters-title .filters-list', function () {
            HeaderFiltersTitle._moveBar($(this));
        });

        $('.header-filters-title .filters-list').each(function () {
            HeaderFiltersTitle._moveBar($(this));
        });
    },
    _moveBar: function (container) {

        if (!container.hasClass('ready')) {
            container.addClass('ready');
            container.append('<li class="bar"></li>');
        }
        var link = container.find('.active');
        var bar = container.find('.bar');
        var width = link.outerWidth() + 5;
        var left = link.offset().left - container.offset().left;
        bar.css({
            width: width + 'px',
            left: left + 'px'
        });
        setTimeout(function () {
            bar.addClass('displayed');
        }, 100);
    },
    onResize: function () {

        $('.header-filters-title .filters-list').each(function () {
            HeaderFiltersTitle._moveBar($(this));
        });
    }
};
