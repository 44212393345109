var Front_IndexController = {
    fondParallax: false,

    // Methods
    init: function () {
        this.onResize($(window).width(), $(window).height());
    },
    indexAction: function () {
        Front_IndexController.fondParallax = $('.homepage-bloc-visuel .fond').get(0);
        $("a[href='#autourdemoi'], a[href='#aroundme']").click(function (e) {
            e.preventDefault();
            var text = $(this).text().trim();
            var lang = $(this).attr('href') == '#aroundme' ? 'en' : 'fr';

            navigator.geolocation.getCurrentPosition(function (position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                App.gotoPage('/'+ lang +'/recherche?location='+encodeURI(text)+'&id_location='+latitude+'%2C'+longitude+'&type_location=geoloc&order=distance');
            });
        });
    },
    onScroll: function (scrollTop) {
        if (App.action === 'index') {
            scrollTop -= $('#homepage').offset().top;
            Front_IndexController.moveBackground(scrollTop / 4);
        }
    },
    onResize: function (windowWidth, windowHeight) {

        if ($('body').hasClass('mobile-app')) {
            windowHeight = 500;
        }

        var $cookie_bar = $(".cookie-toolbar-container.open");
        if (!$cookie_bar.size()) {
            $homepage = $("#homepage");
            $homepage.find(".barre").css("bottom", 0);
            $homepage.find('.form').css("bottom", 0);
        }
    },
    moveBackground: function (position, t) {

        $('body').on('keypress', '.form-search input#q', function () {

        });
        Front_IndexController.fondParallax.style.cssText = CSS.translate3d(0, position, 0, t);
    },
    depotAvisAction: function () {
        // populate stars
        $('input.criteria').each(function (i, res) {
            if ($(res).val() != 0) {
                $element = $('.ratings .criteria[data-id=' + $(res).data('id') + ']');
                $element.find('.note').text($(res).val() + '/5');
                $element.find('.rating-item').eq(5 - $(res).val()).addClass('active');// a cause du RTL
            }
        });

        // populate btn
        if ($("#id_note_occasion").val() != '') {
            $('.btn-occasion').find('.name').append("<span class='active'> : " + $(".btn-occasion a[data-id=" + $("#id_note_occasion").val() + "]").text() + " </span>");
        }

        $('.rating-item').on('click', function () {
            var index = $(this).index();
            $parent = $(this).parent('.container-ratings');
            $parent.find('.rating-item').removeClass('active');
            $(this).addClass('active');

            $parent.find('.note').text((6 - index) + '/5');
            var id = $(this).parents('.criteria').data('id');
            $("#rating_" + id).val(6 - index);
        });

        $('.btn-occasion li a').on('click', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            $("#id_note_occasion").val($(this).data('id'));
            $(this).parents('.btn-occasion').find('.name .active').remove()
            $(this).parents('.btn-occasion').find('.name').append("<span class='active'> : " + $(this).text() + " </span>");
            $(this).parents('ul').find('li').show();
            $(this).parent('li').hide();
            $(this).parents('.btn-occasion').removeClass('open');
        });
    }
};
