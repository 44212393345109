var ModuleReservation = {
    scrollEnabled : false,
    oldScrollTop : 0,
    limitTop : null,
    limitBottom : null,
    heightBlock : null,
    el: {
        container : $("#module-reservation-container"),
        block: $('#module-reservation'),
        blockLimitTop : $("#scrollTopLimitReservation"),
        blockLimitBottom : $("#scrollBottomLimitReservation")
    },
    init: function () {
        this.start();
        this.onScroll($('window').scrollTop())
    },
    start: function () {
        var that = this;
        $("#module-reservation:not(.started)").each(function () {
            var module = $(this);
            module.addClass('started');
            var url = module.find('#module-reservation-url').val();
            var loader = $('#loading-calendar-data');

            module.find('a.module-reservation-ajax').click(function (e) {
                e.preventDefault();
                loader.removeClass('hidden')
                $.ajax({
                    cache: false,
                    url: $(this).attr('href'),
                    success: function (data) {
                        module.replaceWith(data);
                        that.start();
                    }
                });
            });

            module.find('.js-btn-confirm-people').click(function (e) {
                e.preventDefault();
                var adults = parseInt($("#tablebookingform-adults-select").val());
                var children = $(".children-age-select-container select")
                    .map(function(idx, elem){return $(elem).val();})
                    .toArray();

                if (children.length === 0) {
                    children = false;
                }
                loader.removeClass('hidden')

                $.ajax({
                    cache: false,
                    url: $(this).data('url'),
                    data:{
                        people :adults,
                        children: children,
                    },
                    success: function (data) {
                        module.replaceWith(data);
                        that.start();
                    }
                });
            });

            module.find('#tablebookingform-adults-select').change(function (e){
                var adults = parseInt($(this).val());
                var areChildrenAccounted = $("#mr_account_for_children").val() === '1';
                if(areChildrenAccounted) {
                    var max = parseInt($("#mr_max_people").val());
                    var newChildrenMax = max - adults;
                    var lastMax = parseInt($("#tablebookingform-children-select option:last").val());
                    var diff = newChildrenMax - lastMax;
                    if (diff > 0) {
                        for (var i = 0; i < diff; i++) {
                            var optVal = lastMax +i + 1;
                            $("#tablebookingform-children-select").append(
                                '<option value="' + optVal +'">'+ optVal +'</option>'
                            )
                        }
                    } else {
                        $('#tablebookingform-children-select option').slice(diff).remove();
                    }
                }
            });
            module.find('#tablebookingform-children-select').change(function (e){
                e.preventDefault();
                var minimumAge = parseInt($('.people-count').data('minimumchildage'));
                minimumAge = !isNaN(minimumAge) ? minimumAge : 1;
                var selectedAge = Math.max(minimumAge, 12);
                var newValue = $(this).val();
                var currentValue = $('.module-reservation-people .children-age .children-age-select-container').length;
                var diff = newValue - currentValue;
                var $childrenAgeContainer = $('.module-reservation-people .children-age');
                if (diff > 0) {
                    for (var i = 0; i < diff; i++) {
                        var $elem =
                            $('<div class="children-age-select-container"> \
                                 <div class="select-style white">\
                                     <select class="age-select"></select> \
                                 </div>\
                             </div>');
                        for (var j = minimumAge; j <= 17; j++) {
                            $elem.find('select').append('<option value="' + j + '" ' + (j === selectedAge ? 'selected="selected"' : "") + '>' + j + '</option>')
                        }
                        $childrenAgeContainer.append($elem);
                    }
                } else {
                    $('.module-reservation-people .children-age .children-age-select-container').slice(diff).remove();
                }
                if (newValue > 0) {
                    $("#module-reservation .children-age").show();
                } else {
                    $("#module-reservation .children-age").hide();
                }

                var areChildrenAccounted = $("#mr_account_for_children").val() === '1';
                var children = parseInt($("#tablebookingform-children-select").val());

                if(areChildrenAccounted) {
                    var max = parseInt($("#mr_max_people").val());
                    var newChildrenMax = max - children;
                    var lastMax = parseInt($("#tablebookingform-adults-select option:last").val());
                    var sub = newChildrenMax - lastMax;
                    if (sub >= 0) {
                        for (var i = 0; i < sub; i++) {
                            var optVal = lastMax +i + 1;
                            $("#tablebookingform-adults-select").append(
                                '<option value="' + optVal +'">'+ optVal +'</option>'
                            )
                        }
                    } else {
                        $('#tablebookingform-adults-select option').slice(sub).remove();
                    }
                }
            });
            module.find('.module-reservation-hours a').click(function (e) {
                module.find('.module-reservation-hours a').removeClass('active');
                $(this).addClass('active');
                loader.removeClass('hidden');
            });
            var currentDate = new Date();
            module.find('#module-reservation-date').datetimepicker({
                lang: $('#CURRENT_LANG').val(),
                inline: true,
                todayButton: false,
                timepicker: false,
                format: 'd/m/Y',
                scrollMonth: false,
                scrollTime: true,
                scrollInput: false,
                dayOfWeekStart: 1,
                yearStart: currentDate.getFullYear(),
                yearEnd: currentDate.getFullYear() + 2,
                formatDate: 'd/m/Y',
                minDate: '0',
                timepickerScrollbar: false,
                onSelectDate: function (ct, $i) {
                    $.ajax({
                        cache: false,
                        url: url,
                        data: {date: ct.dateFormat('Y-m-d')},
                        success: function (data) {
                            module.replaceWith(data);
                            that.start();
                        }
                    });
                },
                onGenerate: function (e) {
                    console.log('onGenerate', e)
                    that.refreshCells(module, e.dateFormat('Y-m-d'));
                    $('.xdsoft_datetimepicker ').addClass('search-datetimepicker');
                }
            });

        });
    },
    refreshCells: function (module, date) {
        var that = this
        var promos = $('#module-reservation-dates-promos').val().split(',');
        var loader = $('#loading-calendar-data');
        loader.removeClass('hidden')
        if (date) {
            var url = $('input[name="ajax_url"]').val();
            var data = {
                date: date,
                lang: $('input[name="ajax_lang"]').val(),
                activity: $('input[name="ajax_id_activity"]').val(),
                adults: $('input[name="ajax_adults"]').val(),
                children: $('input[name="ajax_children"]').val()
            }
            var myInit = {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            };

            fetch(url, myInit)
                .then(function (response) { return response.json() })
                .then(function (json) {
                    if (json.error) {
                        that.displayError(loader, json.message)
                    }
                    that.changeCalendarDays(module, promos, json.value && json.value.dates ? json.value.dates : []);
                    loader.addClass('hidden')
                })
                .catch(function (reason) {
                    that.displayError(loader, reason)
                    that.changeCalendarDays(module, promos, []);
                    loader.addClass('hidden')
                });
        } else {
            var dates = $('#module-reservation-dates-dispo').val().split(',');
            that.changeCalendarDays(module, promos, dates);
        }
    },
    displayError: function (loader, message) {
        var parent = loader.parent();
        parent.find('.error').remove()
        parent.prepend('<div class="error">' + message + '</div>')
    },
    changeCalendarDays: function (module, promos, dates) {
        for (var promoKey in promos) {
            var promoValue = promos[promoKey];
            if (promoValue && promoValue !== '') {
                promoValue = promoValue.split('-');
                module.find('td[data-month=' + (parseInt(promoValue[1]) - 1) + '][data-year=' + parseInt(promoValue[0]) + '][data-date=' + parseInt(promoValue[2]) + ']').addClass('promo');
            }
        }
        var cells = module.find('td:not(.xdsoft_disabled)').addClass('off');
        for (var dateKey in dates) {
            var dateString = dates[dateKey];
            if (dateString && dateString !== '') {
                dateString = dateString.split('-');
                cells.filter('td[data-month=' + (parseInt(dateString[1]) - 1) + '][data-year=' + parseInt(dateString[0]) + '][data-date=' + parseInt(dateString[2]) + ']').removeClass('off');
            }
        }
        module.find('.off').addClass('xdsoft_disabled').removeClass('off');
    },
    onScroll: function (scrollTop) {

        if (this.scrollEnabled ||
            (this.el.container.size() && this.el.blockLimitBottom.size() && this.el.blockLimitTop.size())) {
            this.scrollEnabled = true;


            if (this.limitTop == null){
                this.limitTop = this.el.blockLimitTop.offset().top;
            }

            if (this.limitBottom == null)
                this.limitBottom = this.el.blockLimitBottom.offset().top - 20;

            if (this.heightBlock == null) {
                this.heightBlock = this.el.container.height();
            }

            if (parseInt(scrollTop+this.heightBlock) < this.limitBottom && scrollTop > this.limitTop) {
                this.el.container.css('top', scrollTop - this.limitTop);
                this.el.container.addClass('fixed');
            }
            if(scrollTop <= this.limitTop){
                // reset
                this.el.container.css('top','');
                this.el.container.removeClass('fixed');
            }
            this.oldScrollTop = scrollTop;
        }
    },
    onResize : function(){
        this.limitBottom = this.limitTop = this.heightBlock = null ;
        this.onScroll( $(window).scrollTop() );
    }
};
