var Excerpt = {
    el : {
        container : '.excerpt-container'
    },
    // Methods
    init: function () {
        var self = this;
        $(self.el.container+' .see-more').on('click',function(e){
            e.preventDefault();
            var $parent = $(this).parents(self.el.container);
            $(this).addClass('hidden');
            $parent.find('.ellipsis').addClass('hidden');
            $parent.find('.text-hidden').removeClass('hidden');
        })
    }
};
