var ClearableFilters = {
    init: function () {
        $('body').on('change', '.js-embedded-select select', function () {
            var $select = $(this);
            var $container = $select.parent();
            var innerLabel = $select.find('option:selected').text();
            $container.find('.selected-inner').html(innerLabel);

            var link = '<a href="#" class="clear-filter icon-delete-1-1"></a>';

            if ($select.val() !== '') {
                if (!$container.hasClass('has-selected-item')) {
                    $container.append(link);
                    $container.addClass('has-selected-item');
                }
            } else {
                $container.removeClass('has-selected-item');
                $container.find(".clear-filter").remove();
            }
        })


        $('body').on('click', '.js-embedded-select .clear-filter', function (e) {
            e.preventDefault();
            var $select = $(this).parent().find('select');
            setTimeout(function (){
                $select.val('').trigger('change');
            });
        })

        $('body').on('change', '.js-embedded-input input', function () {
            var $input = $(this);
            var $container = $input.parent();
            var link = '<a href="#" class="clear-filter icon-delete-1-1"></a>';

            if ($input.val() !== '') {
                if (!$container.hasClass('has-selected-item')) {
                    $container.append(link);
                    $container.addClass('has-selected-item');
                }
            } else {
                $container.removeClass('has-selected-item');
                $container.find(".clear-filter").remove();
            }
        })

        $('body').on('click', '.js-embedded-input .clear-filter', function (e) {
            e.preventDefault();
            var $input = $(this).parent().find('input');
            setTimeout(function () {
                $input.val('').trigger('change');
            });
        })
    }
}