var SWIPER_THUMBS_CONFIGS = {
    pepite: {
        spaceBetween: 14,
        slidesPerView: 4,
        centerInsufficientSlides: true,
    },
};

var SWIPER_CONFIGS = {
    xs: {
        slidesPerView: 2.2,
        breakpoints: {
            350: {
                slidesPerView: 2.4,
                slidesPerGroup: 2,
            },
            430: {
                slidesPerView: 3.4,
                slidesPerGroup: 3,
            },
            530: {
                slidesPerView: 4.4,
                slidesPerGroup: 4,
            },
            650: {
                slidesPerView: 5.4,
                slidesPerGroup: 5,
            },
            750: {
                slidesPerView: 6.4,
                slidesPerGroup: 6,
            },
            850: {
                slidesPerView: 7.4,
                slidesPerGroup: 7,
            },
            920: {
                slidesPerView: 8,
                slidesPerGroup: 8,
            },
        },
    },
    md: {
        slidesPerView: 1.4,
        breakpoints: {
            420: {
                slidesPerView: 1.8,
                slidesPerGroup: 2,
            },
            500: {
                slidesPerView: 2.2,
                slidesPerGroup: 2,
            },
            540: {
                slidesPerView: 2.4,
                slidesPerGroup: 2,
            },
            600: {
                slidesPerView: 2.8,
                slidesPerGroup: 2,
            },
            740: {
                slidesPerView: 3.2,
                slidesPerGroup: 2,
            },
            780: {
                slidesPerView: 3.4,
                slidesPerGroup: 3,
            },
            840: {
                slidesPerView: 3.8,
                slidesPerGroup: 3,
            },
            920: {
                slidesPerView: 4,
                slidesPerGroup: 4,
            },
        },
    },
    lg: {
        slidesPerView: 1.2,
        breakpoints: {
            450: {
                slidesPerView: 1.4,
                slidesPerGroup: 1,
            },
            600: {
                slidesPerView: 2.2,
                slidesPerGroup: 2,
            },
            750: {
                slidesPerView: 2.4,
                slidesPerGroup: 2,
            },
            920: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
        },
    },
    tooltip: {
        slidesPerView: 1.1,
        breakpoints: {
            500: {
                slidesPerView: 1,
            },
        },
    },

    pepite: function (sliderContainerSelector, size) {
        return {
            autoplay: {
                delay: 3000,
            },
            thumbs: {
                swiper: new Swiper(
                    sliderContainerSelector + " .thumbs-swiper",
                    SWIPER_THUMBS_CONFIGS[size]
                ),
            },
            centeredSlides: true,
            rewind: true,
            slidesPerView: 1,
            breakpoints: {
                800: {
                    slidesPerView: 2,
                },
                900: {
                    slidesPerView: 3,
                },
                1000: {
                    slidesPerView: 4,
                },
            },
        };
    },
};
