var Popin = {
    popupInner: false,
    popupInnerContent: false,
    popupElement: false,
    // Methods
    init: function () {
        $("body").on(
            "click",
            "a.popinify:not(.facebook-connect,[data-confirm])",
            function (e) {
                e.preventDefault();
                Popin.open(
                    $(this).attr("href"),
                    undefined,
                    undefined,
                    undefined,
                    $(this).attr("data-popin-classname")
                );
            }
        );
        $("body").on("submit", "#popin form.popinify", function (e) {
            e.preventDefault();
            Popin.open(
                $(this).attr("action"),
                $(this).serializeArray(),
                "POST",
                undefined,
                $(this).attr("data-popin-classname")
            );
        });

        $("body").on(
            "click",
            "#popin:not(.exit-by-btn) a#popin-overlay, #popin:not(.exit-by-btn) a#popin-close-btn",
            function (e) {
                e.preventDefault();
                Popin.close();
            }
        );
        $("body").on("click", ".close-popin", function (e) {
            e.preventDefault();
            Popin.close();
        });
        var param = Popin.getQueryVariable("popinify");
        if (param) {
            Popin.open(decodeURIComponent(param));
        }
    },
    getQueryVariable: function (variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return false;
    },
    open: function (url, data, type, onSuccess, popinClassName) {
        if (typeof data === "undefined") data = {};
        if (typeof type === "undefined") type = "GET";
        if (typeof popinClassName === "undefined") popinClassName = "";
        data.popin = 1;
        Popin._createPopin(popinClassName);
        $.ajax({
            cache: false,
            url: url,
            data: data,
            type: type,
            success: function (data) {
                $("body,html").animate(
                    {
                        scrollTop: 0,
                    },
                    600,
                    function () {
                        $("body").addClass("popin-open");
                    }
                );
                Popin.popupInnerContent.html(data);
                CssRadioCheckbox.parseAll();
                Popin.popupElement.fadeTo(600, 1);

                if (typeof onSuccess === "function") onSuccess();
            },
        });
    },
    close: function () {
        Popin.popupElement.fadeTo(600, 0, function () {
            // reset
            $(this).hide();
            Popin.popupElement.removeClass("exit-by-btn");
            $("body").removeClass("popin-open");
            $(this).find("iframe").remove();
        });
    },
    _createPopin: function (popinClassName) {
        if (Popin.popupElement && Popin.popupElement.size()) {
            return;
        }
        Popin.popupElement = $(
            '<div id="popin" class="' + popinClassName + '"/>'
        );
        Popin.popupElement.append('<a id="popin-overlay"></a>');
        Popin.popupElement.append('<div id="popin-content"></div>');
        Popin.popupInner = $('<div id="popin-inner"></div>');
        Popin.popupInner.append('<a id="popin-close-btn">&times;</a>');
        Popin.popupInnerContent = $('<div id="popin-inner-content"></div>');
        Popin.popupInner.append(Popin.popupInnerContent);
        Popin.popupElement.find("#popin-content").html(Popin.popupInner);
        Popin.popupElement.insertAfter($("main#content"));
    },
};
