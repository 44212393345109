var Twitter = {
    // Methods
    init: function () {

    },
    open : function(href){
        var h = 314;
        var w = 585;

        // Fixes dual-screen position                                Most browsers       Firefox
        var dualScreenLeft = typeof window.screenLeft === 'number' ? window.screenLeft : screen.left;
        var dualScreenTop = typeof window.screenTop === 'number' ? window.screenTop : screen.top;

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;
        var top  = ((height / 2) - (h / 2)) + dualScreenTop;

        window.open(href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=' + h + ',width=' + w + ',top=' + top + ',left=' + left);
    }
};