var ModuleCommand = {
    tabListIds: [],
    saveDataUrl: null,
    activityPriceUrl: null,
    messageStepError: "",
    messageRequiredField: "",
    messageApiError: "",
    init: function () {
        if (typeof commandGlobals !== "undefined") {
            this.tabListIds = commandGlobals.tabListIds;
            this.saveDataUrl = commandGlobals.saveDataUrl;
            this.activityPriceUrl = commandGlobals.activityPriceUrl;
            this.messageStepError = commandGlobals.messageStepError;
            this.messageRequiredField = commandGlobals.messageRequiredField;
            this.messageApiError = commandGlobals.messageApiError;

            var activeStep = commandGlobals.activeStep;
            var that = this;
            var buttonsNext = document.getElementsByClassName("button-next");
            for (var i = 0; i < buttonsNext.length; i++) {
                buttonsNext[i].addEventListener("click", function (e) {
                    that.onClickNext(e, that);
                });
            }
            this.openTab("tab-" + activeStep);
            this.onChangeFormElement();
            this.onChangeSelectAmount();
            this.onChangeAmount();
            this.onChangePersons("adults", "children");
            this.onChangePersons("children", "adults");
            this.onChangeChildren();

            if (activeStep === 2) {
                this.activeStepHeader("tab-1");
            } else if (activeStep === 3) {
                this.activeStepHeader("tab-1");
                this.activeStepHeader("tab-2");
            }
        }
    },
    onClickNext: function (evt, that) {
        that.removeAllTabMessage()
        var currentTarget = evt.currentTarget;
        var selfTab = currentTarget.closest("div.tabContent");
        var selfTabId = selfTab.id;

        var stepIndex = that.tabListIds.indexOf(selfTabId);
        var nexStepId = that.tabListIds[stepIndex + 1];

        if (that.validateFormElement(selfTabId)) {
            that.saveStepData(selfTabId, nexStepId);
        } else {
            that.showTabMessage(selfTabId, that.messageStepError);
        }
    },
    validateFormElement: function (selfTabId) {
        var selfTab = document.getElementById(selfTabId);
        var htmlElements = selfTab.querySelectorAll("input,select,textarea");
        var isValid = true;
        htmlElements.forEach(function (e) {
            if (e.required && !e.value) {
                isValid = false;
                e.classList.add("error");
            }
        });

        return isValid;
    },
    onChangeAmount: function () {
        var labels = document.querySelectorAll("#proposed-amounts span");
        var that = this;
        if (labels) {
            labels.forEach(function (e) {
                e.addEventListener("click", function (e) {
                    that.purgeActiveAmounts();
                    var step1Footer = document.getElementById('amount-step-footer')
                    step1Footer.style.display = 'block'
                    document.getElementById("amount").value =
                        e.target.dataset.value;
                    e.target.classList.add("active");
                });
            });
        }
    },
    onChangeSelectAmount: function () {
        var select = document.querySelector("select#amount");
        var that = this;
        if (!select) {
            return;
        }
        select.addEventListener("change", function (e) {
            that.purgeActiveAmounts();
            var step1Footer = document.getElementById('amount-step-footer')
            step1Footer.style.display = 'block'
        });
    },
    onChangePersons: function (baseSelect, otherSelect) {
        var adults = document.getElementById(baseSelect);
        if (adults) {
            adults.addEventListener("change", function (e) {
                var number = parseInt(e.target.value ? e.target.value : 0);
                var max = parseInt(e.target.dataset.max);
                var newChildrenMax = max - number;
                var otherSelectElement = document.getElementById(otherSelect);
                var options = Array.from(
                    otherSelectElement.getElementsByTagName("option")
                );
                var lastMax = parseInt(
                    options[options.length - 1] &&
                        options[options.length - 1].value
                        ? options[options.length - 1].value
                        : 0
                );
                var diff = newChildrenMax - lastMax;

                if (diff > 0) {
                    for (var i = 0; i < diff; i++) {
                        var optVal = lastMax + i + 1;
                        otherSelectElement.insertAdjacentHTML(
                            "beforeend",
                            '<option value="' +
                                optVal +
                                '">' +
                                optVal +
                                "</option>"
                        );
                    }
                } else {
                    options.slice(diff).forEach(function (i) {
                        otherSelectElement.removeChild(i);
                    });
                    if (options.length + diff === 0) {
                        otherSelectElement.insertAdjacentHTML(
                            "beforeend",
                            '<option value=""> - </option>'
                        );
                    }
                }
            });
        }
    },
    onChangeChildren: function () {
        var children = document.getElementById("children");
        if (children) {
            children.addEventListener("change", function (e) {
                var value = parseInt(e.target.value ? e.target.value : 0);
                var childrenAgeContainer = document.getElementById(
                    "children-age-container"
                );
                var children = Array.from(childrenAgeContainer.children);
                var currentValue = children.length;
                var diff = value - currentValue;
                if (diff > 0) {
                    for (var i = 0; i < diff; i++) {
                        var childrenElement = document.createElement("div");
                        childrenElement.classList.add(
                            "children-age-select-container"
                        );
                        childrenElement.insertAdjacentHTML(
                            "beforeend",
                            '<div class="select-style white"><select name="children_ages[]" class="age-select"></select></div>'
                        );

                        for (var j = 1; j <= 17; j++) {
                            childrenElement
                                .querySelector("select")
                                .insertAdjacentHTML(
                                    "beforeend",
                                    '<option value="' +
                                        j +
                                        '" ' +
                                        (j === 12
                                            ? 'selected="selected"'
                                            : "") +
                                        ">" +
                                        j +
                                        "</option>"
                                );
                        }
                        childrenAgeContainer.appendChild(childrenElement);
                    }
                } else {
                    children.slice(diff).forEach(function (i) {
                        childrenAgeContainer.removeChild(i);
                    });
                }

                var childrenAge = document.getElementById("children-age");

                if (childrenAgeContainer.children.length === 0) {
                    childrenAge.style.display = "none";
                } else {
                    childrenAge.style.display = "block";
                }
            });
        }
    },
    showTabMessage: function (selfTabId, message) {
        var selfTab = document.getElementById(selfTabId);
        selfTab.insertAdjacentHTML(
            "afterbegin",
            '<p class="error-message">' + message + "</p>"
        );
    },
    removeAllTabMessage: function (selfTabId, message) {
        var errors = document.querySelectorAll('#module-gift-card-command p.error-message');
        errors.forEach(function (e) {
            e.parentNode.removeChild(e)
        })
        var errorsInput = document.querySelectorAll('#module-gift-card-command input.error');
        errorsInput.forEach(function (e) {
            e.classList.remove('error')
        })
    },
    purgeActiveAmounts: function () {
        var labels = document.querySelectorAll("#proposed-amounts span");
        labels.forEach(function (e) {
            e.classList.remove("active");
        });
    },
    onChangeFormElement: function () {
        var htmlElements = document.querySelectorAll("input,select,textarea");
        var isValid = true;
        htmlElements.forEach(function (e) {
            e.addEventListener("change", function (e) {
                e.target.className = e.target.className.replace("error", "");
            });
        });

        return isValid;
    },
    saveStepData: function (selfTabId, nexStepId) {
        var data = {};
        var that = this;
        var selfTab = document.getElementById(selfTabId);
        var htmlElements = selfTab.querySelectorAll("input,select,textarea");
        htmlElements.forEach(function (e) {
            if (e.name.includes("[]")) {
                var key = e.name.replace("[]", "");
                if (data[key]) {
                    data[key].push(e.value);
                } else {
                    data[key] = [e.value];
                }
            } else {
                data[e.name] = e.value;
            }
        });

        var myInit = {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        };

        fetch(that.saveDataUrl, myInit)
            .then(function (response) {
                return response.json();
            })
            .then(function (json) {
                if (json.error) {
                    that.showTabMessage(selfTabId, that.messageApiError);
                } else {
                    that.activeStepHeader(selfTabId);
                    that.openTab(nexStepId);
                    that.changeSummaryValues(selfTabId, data);
                }
            })
            .catch(function (reason) {
                that.showTabMessage(selfTabId, that.messageApiError);
            });
    },
    changeSummaryValues: function (selfTabId, data) {
        var that = this;
        var description = document.getElementById("tabs-head-" + selfTabId)
            .querySelector('span.header-description')
        Object.keys(data).forEach(function (key) {
            var value = data[key];
            switch (key) {
                case "adults":
                    description.innerHTML = value;
                    var myInit = {
                        method: "GET",
                        mode: "cors",
                        cache: "no-cache",
                        credentials: "same-origin",
                    };
                    fetch(that.activityPriceUrl, myInit)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (json) {
                            if (!json.error) {
                                document.querySelector(
                                    ".gift-card-summary-container .price-block .total-price-value"
                                ).innerHTML = json.value;
                            }
                        })
                        .catch(function (reason) {
                            console.log(reason);
                        });
                    break;
                case "amount":
                    document.querySelector(
                        ".gift-card-summary-container .price-block .total-price-value"
                    ).innerHTML = value + "€";
                    description.innerHTML = value + "€";
                    break;
                case "receiver":
                    document.getElementById("gift-card-summary-for").innerHTML =
                        value;
                    description.innerHTML = value;
                    break;
                case "transmitter":
                    document.getElementById(
                        "gift-card-summary-from"
                    ).innerHTML = value;
                    break;
                default:
                    break;
            }
        });
    },
    openTab: function (selfTabId) {
        var that = this;
        document
            .querySelectorAll("#step-content .tabContent")
            .forEach(function (e) {
                e.style.display = "none";
            });
        document.querySelectorAll("#tabs li").forEach(function (e) {
            e.classList.remove("active");
        });

        var header = document.getElementById("tabs-head-" + selfTabId);
        var headerDescription = header.querySelector('.header-description');
        var defaultDescription = headerDescription.dataset.default;
        header.classList.remove("passed");
        header.classList.remove("checked");
        header.classList.add("active");
        headerDescription.innerHTML = defaultDescription
        header.removeEventListener("click", function () {
            that.openTab(selfTabId);
        });
        document.getElementById(selfTabId).style.display = "block";
    },
    activeStepHeader: function (selfTabId) {
        var element = document.getElementById("tabs-head-" + selfTabId);
        var that = this;
        element.addEventListener("click", function () {
            that.openTab(selfTabId);
        });
        element.classList.remove("active");
        element.classList.add("passed");
        element.classList.add("checked");
        that.removeAllTabMessage()
    },
};
